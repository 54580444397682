import { useState } from "react";
import { FormInput } from "./form";
import { Button } from "./ui/button";

export function CustomDataInput({ value, onDataChanged }) {
  const [customData, setCustomData] = useState([{ key: "", value: "" }]);

  useState(() => {
    if (value) {
      setCustomData(value);
    }
  }, [value]);

  const handleAddCustomData = () => {
    setCustomData([...customData, { key: "", value: "" }]);
    onDataChanged([...customData, { key: "", value: "" }]);
  };

  const handleRemoveCustomData = (index) => {
    setCustomData(customData.filter((_, idx) => idx !== index));
    onDataChanged(customData.filter((_, idx) => idx !== index));
  };

  const handleChange = (index, field, value) => {
    const newCustomData = customData.map((data, idx) => {
      if (idx === index) {
        return { ...data, [field]: value };
      }
      return data;
    });
    setCustomData(newCustomData);

    onDataChanged(newCustomData);
  };

  return (
    <div className="flex flex-col gap-2">
      {customData.map((data, index) => (
        <div className="flex items-center justify-between gap-2" key={index}>
          <FormInput
            type={"text"}
            name={"key"}
            placeholder={"Custom Data Subject"}
            onChange={(e) => handleChange(index, "key", e.target.value)}
            value={data.key}
          ></FormInput>

          <FormInput
            type={"text"}
            name={"value"}
            placeholder={"Value"}
            onChange={(e) => handleChange(index, "value", e.target.value)}
            value={data.value}
          ></FormInput>

          <Button
            variant={"destructive"}
            type="button"
            onClick={() => handleRemoveCustomData(index)}
          >
            Remove
          </Button>
        </div>
      ))}
      <Button type="button" onClick={handleAddCustomData}>
        Add New Custom Data
      </Button>
    </div>
  );
}
