import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import {
  getCommission,
  getCommissionUploadDetail,
  getPayment,
  postCommissionInvoiceUpload,
} from "../../../data";

import {
  Link,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppLayout } from "../shared/layout";
import { Button } from "@/components/ui/button";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useRef, useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { PDFDownloadLink, PDFViewer, pdf } from "@react-pdf/renderer";
import { InvoicePdf } from "@/components";
export function CommissionInvoiceUploadManuelPdfScreen() {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const [screenLoading, setScreenLoading] = useState(false);

  const pathname = useResolvedPath().pathname.split("/")[2];
  const navigate = useNavigate();

  const pdfDowloadLinkRef = useRef();

  const { data, isLoading, error } = useQuery({
    queryKey: ["commissionDetail", pathname],

    queryFn: async () => await getCommissionUploadDetail(pathname),
    retry: 1,
  });

  const mutation = useMutation({
    mutationKey: ["uploadPaymentInvoice"],

    mutationFn: (data) => {
      return postCommissionInvoiceUpload(data);
    },
    onSuccess: (response) => {
      setScreenLoading(false);
      //navigate("/payments");
      if (response.status === 200) {
        queryClient.invalidateQueries(["uploadPaymentInvoice", pathname]);
      }
    },
    onError: (error) => {
      setScreenLoading(false);
      toast({
        title: "Hata",
        description: "Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.",
        variant: "destructive",
      });
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const onSubmit = async (values) => {
    setScreenLoading(true);

    // Fatura tarihini ayarla
    setValue(
      "invoiceDate",
      new Intl.DateTimeFormat("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      }).format(new Date())
    );

    var pdfData = {
      invoiceId: data.data.id,
      email: data.data.email,
      lucaId: getLucaId(getValues("bankAccountHolder")) || "",
      recipientName: getValues("recipientName") || "",
      finalAmount: data.data.finalAmount,
      bankAccountHolder: getValues("bankAccountHolder") || "",
      swift: getValues("swift") || "",
      iban: getValues("iban") || "",
      invoiceDate: getValues("invoiceDate") || "",
      phoneNumber: getValues("phoneNumber") || "",
    };

    if (
      !pdfData.recipientName ||
      !pdfData.bankAccountHolder ||
      !pdfData.iban ||
      !pdfData.phoneNumber
    ) {
      setScreenLoading(false);
      toast({
        title: "Lütfen tüm alanları doldurunuz",
        message: "",
        type: "error",
      });
      return;
    }

    const formData = new FormData();
    Object.entries(pdfData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("fileName", data.data.influencerName);

    try {
      var blob = await pdf(InvoicePdf({ data: pdfData })).toBlob();
      formData.append("file", blob, "invoice.pdf");

      mutation.mutate({
        path: pathname,
        data: formData,
        invoiceId: data.data.payment.invoiceId,
      });
    } catch (error) {
      setScreenLoading(false);
      toast({
        title: "Fatura oluşturulurken bir hata oluştu",
        message: "Lütfen daha sonra tekrar deneyiniz",
        type: "error",
      });
    }
  };
  const getLucaId = (bankAccountHolder) => {
    if (data.data.client) {
      if (data.data.client.lucaAccountName == bankAccountHolder) {
        return data.data.client.lucaAccount.lucaId;
      }
    }
    return "";
  };

  if (isLoading || screenLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Card className="w-[350px] ">
          <CardHeader>
            <CardTitle>Upload Invoice</CardTitle>
            <CardDescription>
              Please wait while we are processing your request
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-center">
              <div
                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Card className="w-[350px] ">
          <CardHeader>
            <CardTitle>Error</CardTitle>
            <CardDescription>
              Something went wrong. Please try again later. If the problem
              persists, please contact our support team.
            </CardDescription>
          </CardHeader>
        </Card>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center h-screen">
      {data.data.status == "Invoice Uploaded" ? (
        <div className="flex items-center justify-center h-screen">
          <Card className="w-[350px] ">
            <CardHeader>
              <CardTitle>Success</CardTitle>
              <CardDescription>
                Your invoice has been sent successfully. You can close this page
              </CardDescription>
            </CardHeader>
          </Card>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="w-[350px] ">
            <CardHeader>
              <CardTitle>Fatura İşlemleri</CardTitle>
              <CardDescription>
                Komisyon faturası olustumak ve onaylamak için lütfen odeme
                bilgilerinizi giriniz. Banka bilgilerini girdikten sonra lutfen
                onaylayiniz.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid w-full items-center gap-4">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="name">Email</Label>
                  <h1 id="name">{data.data.email}</h1>
                </div>

                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="framework">Ad-Soyadı</Label>
                  <span className="text-red-500 text-sm">
                    Lütfen sadece Türkçe karakterler kullanın.
                  </span>
                  <Input
                    id="recipientName"
                    type="text"
                    onChange={(e) => {
                      setValue("recipientName", e.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="framework">Telefon Numarası</Label>

                  <Input
                    id="phoneNumber"
                    type="text"
                    onChange={(e) => {
                      setValue("phoneNumber", e.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="framework">Fatura Tarihi</Label>
                  <h1 id="name">
                    {new Intl.DateTimeFormat("en-GB", {
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                    }).format(new Date())}
                  </h1>
                </div>

                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="framework">Alıcı Adı Soyadı</Label>
                  <span className="text-red-500 text-sm">
                    Lütfen sadece Türkçe karakterler kullanın.
                  </span>
                  <Input
                    id="bankAccountHolder"
                    type="text"
                    onChange={(e) => {
                      setValue("bankAccountHolder", e.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="framework">IBAN veya Kart No</Label>

                  <Input
                    id="iban"
                    type="text"
                    onChange={(e) => {
                      setValue("iban", e.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="framework">
                    SWIFT (Yoksa boş geçilebilir)
                  </Label>

                  <Input
                    id="swift"
                    type="text"
                    onChange={(e) => {
                      setValue("swift", e.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="name">Fatura Tutarı</Label>
                  <h1 id="name">{data.data.finalAmount} $</h1>
                </div>
              </div>
            </CardContent>
            <CardFooter className="flex justify-between">
              <div></div>
              <Button type="submit">Fatura Oluştur ve Gönder</Button>
            </CardFooter>
          </Card>
        </form>
      )}
    </div>
  );
}
