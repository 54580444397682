import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { AdminLayout } from "../shared/layout";
import { deleteUser, getUsers } from "../../../data";
import { UserCard } from "../../../components";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";

export function UserListScreen() {
  const { toast } = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
  });

  const pageSettings = {
    title: "User List",
    actions: [
      {
        name: "Create New User",
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          navigate("/admin/users/add");
        },
      },
    ],
  };

  const mutation = useMutation({
    mutationKey: ["deleteUser"],
    mutationFn: (data) => {
      return deleteUser(data);
    },
    onSuccess: (response) => {
      toast({
        title: "User Deleted",
        message: `User ${response.data.email} has been deleted`,
        type: "success",
      });
      queryClient.invalidateQueries(["users"]);
    },
  });

  const goToEdit = (id) => () => {
    window.location.href = `/admin/users/${id}`;
  };

  const onDeleteUser = (id) => {
    mutation.mutate(id);
  };

  if (isLoading) {
    return (
      <AdminLayout>
        <div>Loading...</div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout
      title={pageSettings.title}
      actions={pageSettings.actions}
      loading={isLoading}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4 gap-4 ">
        {data.data.length ? (
          data.data.map((item) => (
            <UserCard
              name={item.name}
              email={item.email}
              roles={item.roles}
              editClick={goToEdit(item.id)}
              deleteClick={() => onDeleteUser(item.id)}
            />
          ))
        ) : (
          <div className=" bg-red-300">Not Found Users</div>
        )}
      </div>
    </AdminLayout>
  );
}
