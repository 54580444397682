import { makeAutoObservable, set } from "mobx";
import { getMyPermission } from "../data";

class AuthStore {
  isLogin = false;
  token = "";
  permissions = [];
  user = {};
  constructor() {
    makeAutoObservable(this);
  }

  isHaveTokenLogin() {
    var isHaveToken = localStorage.getItem("token");
    return isHaveToken == "" ? false : true;
  }

  isHavePermission(permissionName) {
    var permissionList = this.getPermissions();
    if (permissionList == null) {
      return false;
    }

    //console.log("permissionList", permissionList.length);
    var isHaveAdmin = permissionList.some((x) => x == "ADMIN");

    //console.log("isHaveAdmin", isHaveAdmin);
    var isHavePermission = permissionList.some(
      (x) => x == permissionName || x == "ADMIN"
    );

    //console.log("isHavePermission", isHavePermission);
    return isHavePermission;
  }

  setUser(token) {
    this.token = token;
    localStorage.setItem("token", token);
    this.isLogin = true;
  }
  setProfile(user) {
    this.user = user;
    this.setPermissions(user.permissions);
    // console.log("permissions", user.permissions);
  }
  setPermissions(permissions) {

     var combinePermissionList = [];
     


    localStorage.setItem("permissions", JSON.stringify(permissions));
    this.permissions = permissions;
  }
  getPermissions() {
    var permissionList = this.permissions;

    if (
      permissionList == [] ||
      permissionList == null ||
      permissionList == ""
    ) {
      //console.log("getMyPermission");
      var localPermission = localStorage.getItem("permissions");
      if (localPermission != null && localPermission != "") {
        permissionList = JSON.parse(localStorage.getItem("permissions"));
      }
    }

    return permissionList;
  }

  logout() {
    this.isLogin = false;
    localStorage.setItem("token", "");
    this.token = "";
    localStorage.setItem("permissions", "");

    this.reset();
  }

  reset() {
    this.isLogin = false;
    this.token = "";
    this.permissions = [];
    this.user = {};
  }
}

export default new AuthStore();
