import { Link, useNavigate } from "react-router-dom";

import AuthStore from "../../stores/AuthStore";
import { useQueryClient } from "@tanstack/react-query";
import { Switch } from "../ui/switch";

import { Label } from "../ui/label";
import { useTableView } from "@/hooks/useTableView";

export function NavbarApp() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isTableView, setTableView } = useTableView();


  const appRouteMenu = [
    {
      displayName: "Clients",
      path: "/clients",
      isHavePermission: AuthStore.isHavePermission("CLIENT_READ"),
    },
    {
      displayName: "Agencies",
      path: "/agencies",
      isHavePermission: AuthStore.isHavePermission("AGENCY_READ"),
    },
    {
      displayName: "Payments",
      path: "/payments",
      isHavePermission: AuthStore.isHavePermission("PAYMENT_READ"),
    },
    {
      displayName: "Confirmations",
      path: "/confirmations",
      isHavePermission: AuthStore.isHavePermission("CONFIRMATION_READ"),
    },
  ];

  const logout = () => {
    // localstorage  must be clear in here
    queryClient.clear();

    AuthStore.logout();
    navigate("/");
  };
  return (
    <nav className="border-b-2">
      <div className="max-w-7xl mx-auto  px-4 ">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link
              className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
              to="/dashboard"
            >
              Newtv Portal
            </Link>

            <div className="flex ml-10">
              <div className=" flex items-baseline space-x-4">
                <Link
                  className="text-black px-3 py-2  text-sm font-medium"
                  to="/dashboard"
                >
                  Home
                </Link>
              </div>
              {appRouteMenu.map(
                (menu) =>
                  menu.isHavePermission && (
                    <div className=" flex items-baseline space-x-4">
                      <Link
                        className="text-black px-3 py-2  text-sm font-medium"
                        to={menu.path}
                      >
                        {menu.displayName}
                      </Link>
                    </div>
                  )
              )}
            </div>
          </div>

          <div className="ml-4 flex items-center md:ml-6">
            <div className="flex items-center space-x-2">
              <Switch
                id="view-mode"
                checked={isTableView}
                onCheckedChange={setTableView}
              />
              <Label htmlFor="view-mode">
                {isTableView ? "Tablo Görünümü" : "Grid Görünümü"}
              </Label>
            </div>
            <div className="ml-3 ">
              {AuthStore.isHavePermission("ADMIN") == true && (
                <Link
                  className="text-black px-3 py-2 rounded-md text-sm font-medium"
                  to="/admin"
                >
                  Admin
                </Link>
              )}
            </div>
            <div className="ml-3 ">
              <Link
                className="text-black px-3 py-2 rounded-md text-sm font-medium"
                to="/profile"
              >
                Profile
              </Link>
            </div>
            <div className="ml-3 ">
              <Link
                className="text-black px-3 py-2 rounded-md text-sm font-medium"
                onClick={logout}
                to="/"
              >
                Sign out
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
