import React from "react";
import { LandingPageLayout } from "../shared/layout";
import { Link } from "react-router-dom";

export function LandingPageScreen() {
  return (
    <LandingPageLayout>
      <div className="flex flex-col min-h-[100dvh]">
        <main className="flex-1 flex flex-col items-center justify-center px-4 md:px-6 space-y-6">
          <div className="max-w-3xl text-center space-y-4">
            <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl md:text-6xl">
              Newtv Portal
            </h1>
            <p className="text-gray-500 md:text-xl dark:text-gray-400">
              For influencers and content creators
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
              <Link
                className="inline-flex h-10 items-center justify-center rounded-md bg-gray-900 px-6 text-sm font-medium text-gray-50 shadow transition-colors hover:bg-gray-900/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:bg-gray-50 dark:text-gray-900 dark:hover:bg-gray-50/90 dark:focus-visible:ring-gray-300"
                href="#"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </main>
      </div>
    </LandingPageLayout>
  );
}
