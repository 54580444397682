import { Link, useHistory } from "react-router-dom";
import AuthStore from "../../stores/AuthStore";
import { observer } from "mobx-react";

export const NavbarLandingPage = observer(() => {
  return (
    <nav className="border-b-2">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link
              className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
              to="/"
            >
              Newtv Portal
            </Link>

            <div className="block">
              <div className="ml-10 flex items-baseline space-x-4">
                <Link
                  to="/about"
                  href="#"
                  className="text-black px-3 py-2  text-sm font-medium"
                >
                  About
                </Link>
              </div>
            </div>
          </div>

          <div className="ml-4 flex items-center md:ml-6">
            {AuthStore.isLogin || AuthStore.isHaveTokenLogin() ? (
              <div className="ml-3 ">
                <Link
                  to="/dashboard"
                  className="text-black text-bold px-3 py-2 rounded-md text-sm font-medium bg-red-300"
                >
                  Go To Dashboard
                </Link>
              </div>
            ) : (
              <div className="ml-3 ">
                <Link
                  to="/login"
                  className="text-black px-3 py-2 rounded-md text-sm font-medium"
                >
                  Login
                </Link>

                <Link
                  to="/register"
                  className="text-black px-3 py-2 rounded-md text-sm font-medium"
                >
                  Register
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
});
