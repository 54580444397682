import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AdminLayout } from "../shared/layout";
import { createUser, getUser, updateUser } from "../../../data";

import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { CardBox } from "../shared/CardBox";

export function UserAddScreen() {
  const queryClient = useQueryClient();
  const pageSettings = {
    title: "Create User",
    actions: [
      {
        name: "Go To List",
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          navigate("/admin/users");
        },
      },
    ],
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const mutation = useMutation({
    mutationKey: ["createUser"],
    mutationFn: (data) => {
      return createUser(data);
    },
    onSuccess: (response) => {
      navigate("/admin/users");
    },
  });
  const onSubmit = async (data) => {
    const { email, name, password } = data;

    mutation.mutate({ email, name, password });
  };
  const validatePassword = (value) => {
    const hasLowerCase = /[a-z]/.test(value);
    const hasUpperCase = /[A-Z]/.test(value);
    const hasDigit = /\d/.test(value);
    const hasSpecialChar = /[!@#$%^&*]/.test(value);

    if (value.length < 8) {
      return "Password must be at least 8 characters long";
    }

    if (!(hasLowerCase && hasUpperCase && hasDigit && hasSpecialChar)) {
      return "Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character";
    }

    return true;
  };

  return (
    <AdminLayout title={pageSettings.title} actions={pageSettings.actions}>
      <CardBox>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Name
            </label>

            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              placeholder="name"
              {...register("name", {
                required: "Name is required",
              })}
            />
            {errors.name && <p>{errors.name.message}</p>}
          </div>

          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>

            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="email"
              placeholder="Email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email format",
                },
              })}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>

            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder="Password"
              {...register("password", {
                required: "Password is required",
                validate: validatePassword,
              })}
            />
            {errors.password && <p>{errors.password.message}</p>}
          </div>

          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Create User
            </button>
          </div>
        </form>
      </CardBox>
    </AdminLayout>
  );
}
