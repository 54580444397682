import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { createClient, getAgencies } from "../../../data";

import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppLayout } from "../shared/layout";
import {
  CustomDataInput,
  FormInput,
  SocialMediaInput,
} from "../../../components";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { toast } from "@/components/ui/use-toast";

export function ClientAddScreen() {
  const { data, isLoading } = useQuery({
    queryKey: ["agencies"],
    queryFn: getAgencies,
  });
  const pageSettings = {
    title: "Create Client",
    actions: [
      {
        name: "Go To List",
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          navigate("/clients");
        },
      },
    ],
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const watchIsHaveAgency = watch("isHaveAgency");
  const mutation = useMutation({
    mutationKey: ["createClient"],
    mutationFn: (data) => {
      return createClient(data);
    },
    onSuccess: (response) => {
      navigate("/clients");
    },
  });

  const onSubmit = async (data) => {
    mutation.mutate({
      email: data.email,
      name: data.name,
      phoneNumber: data.phoneNumber,
      billingName: data.billingName,
      bankName: data.bankName,
      IBAN: data.IBAN,
      SWIFT: data.SWIFT,
      customData: data.customData,
      isHaveAgency: data.isHaveAgency,
      manager: data.manager,
      socialMediaAccounts: data.socialMediaAccounts,
      agencyId: data.agencyId,
      lucaId: data.lucaId,
      lucaAccountName: data.lucaAccountName,
      currency: data.currency,
      country: data.country,
      newTvAccountManager: data.newTvAccountManager,
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <AppLayout title={pageSettings.title} actions={pageSettings.actions}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-2  ">
          <div className="grid w-1/2 gap-2">
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                Name
              </label>

              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                placeholder="Name"
                {...register("name", {
                  required: "Name is required",
                })}
              />
              {errors.name && <p>{errors.name.message}</p>}
            </div>
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>

              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="text"
                placeholder="Name"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Invalid email format",
                  },
                })}
              />
              {errors.email && <p>{errors.email.message}</p>}
            </div>

            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="phoneNumber"
              >
                Phone Number
              </label>

              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="phoneNumber"
                type="text"
                placeholder="Phone Number"
                {...register("phoneNumber", {
                  required: "Phone Number is required",
                })}
              />
              {errors.phoneNumber && <p>{errors.phoneNumber.message}</p>}
            </div>

            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="billingName"
              >
                Billing Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="billingName"
                type="text"
                placeholder="Billing Name"
                {...register("billingName", {
                  required: "Billing Name is required",
                })}
              />
              {errors.billingName && <p>{errors.billingName.message}</p>}
            </div>

            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="bankName"
              >
                Bank Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="bankName"
                type="text"
                placeholder="Bank Name"
                {...register("bankName", {
                  required: "Bank Name is required",
                })}
              />
              {errors.bankName && <p>{errors.bankName.message}</p>}
            </div>
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="lucaId"
              >
                Luca ID
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="lucaId"
                type="text"
                placeholder="Luca ID"
                {...register("lucaId")}
              />
              {errors.lucaId && <p>{errors.lucaId.message}</p>}
            </div>
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="lucaAccountName"
              >
                Luca Account Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="lucaAccountName"
                type="text"
                placeholder="Luca Account Name"
                {...register("lucaAccountName")}
              />
              {errors.lucaAccountName && (
                <p>{errors.lucaAccountName.message}</p>
              )}
            </div>
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="currency"
              >
                Currency
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="currency"
                type="text"
                placeholder="Currency"
                {...register("currency", {
                  required: "Currency  is required",
                })}
              />
              {errors.currency && <p>{errors.currency.message}</p>}
            </div>
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="country"
              >
                Country
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="country"
                type="text"
                placeholder="Country"
                {...register("country", {
                  required: "Country  is required",
                })}
              />
              {errors.country && <p>{errors.country.message}</p>}
            </div>
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="newTvAccountManager"
              >
                New Tv Manager
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="newTvAccountManager"
                type="text"
                placeholder="New Tv Account Manager"
                {...register("newTvAccountManager", {
                  required: "New Tv Account Manager  is required",
                })}
              />
              {errors.newTvAccountManager && (
                <p>{errors.newTvAccountManager.message}</p>
              )}
            </div>
          </div>

          <div className="grid w-1/2 gap-2">
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <Checkbox
                  id="isHaveAgency"
                  name="isHaveAgency"
                  onCheckedChange={(data) => {
                    setValue("isHaveAgency", data);
                  }}
                  {...register("isHaveAgency")}
                />

                <label
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  htmlFor="isHaveAgency"
                >
                  Is Have Agency
                </label>
              </div>

              {watchIsHaveAgency && (
                <div>
                  <Select {...register("agencyId")}>
                    <SelectTrigger className="w-[300px]">
                      <SelectValue placeholder="Select a Manager" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>Managers</SelectLabel>

                        {data.data.agencies.map((agency) => (
                          <SelectItem
                            value={agency.id.toString()}
                            key={agency.id}
                          >
                            {agency.name}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              )}
            </div>

            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="IBAN"
              >
                IBAN
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="IBAN"
                type="text"
                placeholder="IBAN"
                {...register("IBAN", {
                  required: "IBAN is required",
                })}
              />
              {errors.IBAN && <p>{errors.IBAN.message}</p>}
            </div>

            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="SWIFT"
              >
                SWIFT
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="SWIFT"
                type="text"
                placeholder="SWIFT"
                {...register("SWIFT", {
                  required: "SWIFT is required",
                })}
              />
              {errors.SWIFT && <p>{errors.SWIFT.message}</p>}
            </div>

            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="socialMediaAccounts"
              >
                Social Media Accounts
              </label>
              <SocialMediaInput
                onDataChanged={(data) => {
                  console.log("data", data);
                  setValue("socialMediaAccounts", data);
                }}
              />

              {errors.socialMediaAccounts && (
                <p>{errors.socialMediaAccounts.message}</p>
              )}
            </div>

            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="customData"
              >
                Custom Data
              </label>

              <CustomDataInput
                onDataChanged={(data) => {
                  setValue("customData", data);
                }}
              />

              {errors.customData && <p>{errors.customData.message}</p>}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Create Client
          </button>
        </div>
      </form>
    </AppLayout>
  );
}
