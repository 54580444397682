import api from "../api";

export const getConfirmations = (data) => {
  return api.getApi().get(api.getBaseUrl() + "confirmations");
};
export const getConfirmation = (id) => {
  return api.getApi().get(api.getBaseUrl() + "confirmations/" + id);
};
export const createConfirmation = (data) => {
  return api.getApi().post(api.getBaseUrl() + "confirmations", data);
};
export const updateConfirmation = (id, data) => {
  return api.getApi().put(api.getBaseUrl() + "confirmations/" + id, data);
};
export const deleteConfirmation = (id) => {
  return api.getApi().delete(api.getBaseUrl() + "confirmations/" + id);
};
export const uploadConfirmationWithExcel = (data) => {
  return api
    .getApi()
    .post(api.getBaseUrl() + "confirmations/uploadExcel", data);
};

export const sendConfirmationMails = (id) => {
  return api
    .getApi()
    .post(
      api.getBaseUrl() + "confirmations/" + id + "/send-confirmation-mails"
    );
};

export const getConfirmationUploadDetail = (path) => {
  return api.getApiWithoutToken().get(api.getBaseUrl() + "confirm/" + path);
};
export const postConfirmationEmail = (data) => {
  return api
    .getApiWithoutToken()
    .post(api.getBaseUrl() + "confirm/" + data.path, data.data);
};
