import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AppLayout } from "../shared/layout";
import { AgencyCard, SearchView } from "../../../components";
import {
  deleteAgency,
  getAgencies,
  uploadAgencyWithExcel,
} from "../../../data";
import ListView from "../../../components/list-view";
import { Button } from "@/components/ui/button";
import { countryOptions } from "@/utils/country-options";

export function AgencyListScreen() {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [searchParams, setSearchParams] = useState({
    name: "",
    country: "",
  });
  const [queryParams, setQueryParams] = useState({
    name: "",
    country: "",
  });

  const searchTimeoutRef = useRef(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isProcess, setIsProcess] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ["agencies", page, pageSize, queryParams],
    queryFn: () =>
      getAgencies({
        page,
        pageSize,
        nameFilter: queryParams.name,
        countryFilter: queryParams.country,
      }),
  });

  const fileSelect = React.useRef(null);

  const pageSettings = {
    title: "Agency List",
    actions: [
      {
        name: "Excel Upload",
        color: "bg-red-100 hover:bg-red-400 hover:text-black text-red-800",
        icon: "add-icon",
        onClick: () => {
          fileSelect.current.click();
        },
      },
      {
        name: "Create New Agency",
        color: "bg-blue-500 hover:bg-blue-700 text-white",
        icon: "add-icon",
        onClick: () => {
          navigate("/agencies/add");
        },
      },
    ],
  };

  const goToEdit = (id) => () => {
    window.location.href = `/agencies/${id}`;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    setIsProcess(true);

    uploadAgencyWithExcel(formData).then(() => {
      setIsProcess(false);
      fileSelect.current.value = "";
      queryClient.invalidateQueries("agencies");
    });
  };

  const mutation = useMutation({
    mutationKey: ["deleteAgency"],
    mutationFn: (data) => deleteAgency(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["agencies"]);
    },
  });

  const onDeleteAgency = (id) => {
    mutation.mutate(id);
  };

  const handleSearch = (item) => {
    const newSearchParams = { ...searchParams, [item.key]: item.value };
    setSearchParams(newSearchParams);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      setQueryParams(newSearchParams);
      setPage(1);
    }, 300);
  };

  const columns = [
    { key: "name", label: "Name" },
    { key: "email", label: "Email" },
    { key: "country", label: "Country" },
    { key: "clientCount", label: "Client Count" },
    {
      key: "actions",
      label: "Actions",
      render: (row) => (
        <div>
          <Button onClick={goToEdit(row.id)}>Edit</Button>
        </div>
      ),
    },
  ];

  return (
    <AppLayout
      title={pageSettings.title}
      actions={pageSettings.actions}
      loading={isLoading || isProcess}
    >
      <input
        ref={fileSelect}
        hidden
        type="file"
        onChange={handleFileChange}
        className="mb-4"
      />

      <SearchView
        searchParams={searchParams}
        handleSearch={handleSearch}
        countryOptions={countryOptions}
      />

      <ListView
        isLoading={isLoading}
        data={data?.data.agencies}
        columns={columns}
        totalItems={data?.data.totalItems}
        enablePagination={true}
        itemsPerPage={pageSize}
        currentPage={page}
        onPageChange={setPage}
        renderGridItem={(item) => (
          <AgencyCard
            key={item.id}
            name={item.name}
            email={item.email}
            customerCount={item.clientCount}
            editClick={goToEdit(item.id)}
          />
        )}
      />
    </AppLayout>
  );
}
