import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  getConfirmationUploadDetail,
  postConfirmationEmail,
} from "../../../data";

import { Button } from "@/components/ui/button";
import { useForm } from "react-hook-form";
import { useNavigate, useResolvedPath } from "react-router-dom";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";

export function ConfirmationEmailConfirmScreen() {
  const queryClient = useQueryClient();

  const [isSuccess, setIsSuccess] = useState(false);

  const pathname = useResolvedPath().pathname.split("/")[2];
  const navigate = useNavigate();

  const { data, isLoading, error } = useQuery({
    queryKey: ["confirmationDetail", pathname],
    queryFn: async () => await getConfirmationUploadDetail(pathname),
  });

  const mutation = useMutation({
    mutationKey: ["confirmConfirmationMail"],
    mutationFn: (data) => {
      return postConfirmationEmail(data);
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(["confirmationDetail", pathname]);
      setIsSuccess(true);
    },
  });

  const reject = async (values) => {
    mutation.mutate({
      path: pathname,
      data: {
        isApproved: false,
        isRejected: true,
      },
    });
  };

  const approve = async (values) => {
    console.log(values);

    mutation.mutate({
      path: pathname,
      data: {
        isApproved: true,
        isRejected: false,
      },
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex items-center justify-center h-screen">
      {data.data.isApproved == false && data.data.isRejected == false ? (
        <form>
          <Card className="w-[350px] ">
            <CardHeader>
              <CardTitle>NewTv Muhasebe Mutabakat</CardTitle>
              <CardDescription>
                Bu mutabakati onaylamak için lütfen aşağıdaki bilgileri kontrol
                edip onaylayınız.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid w-full items-center gap-4">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="name">E-posta</Label>
                  <h1 id="name">{data.data.email}</h1>
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="name">Toplam</Label>
                  <h1 id="name">{data.data.total}</h1>
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="name">Kalan</Label>
                  <h1 id="name">{data.data.remaining}</h1>
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="name">Ödenen</Label>
                  <h1 id="name">{data.data.paid}</h1>
                </div>
              </div>
            </CardContent>
            <CardFooter className="flex justify-between">
              <Button onClick={() => reject()} variant="destructive">
                Reddet
              </Button>
              <Button onClick={() => approve()}>Onayla</Button>
            </CardFooter>
          </Card>
        </form>
      ) : (
        <div className="flex items-center justify-center h-screen">
          <Card className="w-[350px] ">
            <CardHeader>
              <CardTitle>Success</CardTitle>
              <CardDescription>
                Your confirmation has been sent successfully
              </CardDescription>
            </CardHeader>
          </Card>
        </div>
      )}
    </div>
  );
}
