import { Input } from "../ui/input";
import { Label } from "../ui/label";

export function FormInput({
  className,
  type,
  name,
  label,
  errorMessage,
  placeholder,
  ...props
}) {
  return (
    <div className="grid w-full max-w-sm items-center ">
      <Label htmlFor={name}>{label}</Label>
      <Input type={type} id={name} placeholder={placeholder} {...props} />
      {errorMessage && <Label className="text-red-600">{errorMessage}</Label>}
    </div>
  );
}
