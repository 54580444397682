import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { getConfirmation, sendConfirmationMails } from "../../../data";

import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppLayout } from "../shared/layout";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useToast } from "@/components/ui/use-toast";
import { ConfirmationCard, ConfirmationDetailCard } from "@/components";

export function ConfirmationDetailScreen() {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { id } = useParams();
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: async ({ confirmationId }) => {
      await sendConfirmationMails(confirmationId);
    },
    onSuccess: () => {
      toast("Invoice mails sent successfully", { type: "success" });
    },
  });

  const pageSettings = {
    title: "Confirmation Detail",
    actions: [
      {
        name: "Go To List",
        color: "primary",
        icon: "add-icon",
        onClick: () => {
          navigate("/confirmations");
        },
      },
      {
        name: "Send Invoice Mails",
        color: "primary",
        icon: "add-icon",
        onClick: () => {
          mutation.mutate({ confirmationId: id });
        },
      },
    ],
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ["confirmation", id],
    queryFn: async () => await getConfirmation(id),
  });

  if (isLoading) {
    return (
      <AppLayout>
        <div>Loading...</div>
      </AppLayout>
    );
  }

  return (
    <AppLayout
      title={pageSettings.title + " - " + id}
      actions={pageSettings.actions}
    >
      <div className="flex justify-between gap-3 flex-wrap">
        <div className="flex-1">
          <Card>
            <CardHeader className=" ">
              <CardTitle className="text-xl pb-2">
                {" "}
                Confirmation Detail
              </CardTitle>
              <CardContent className="p-0" s>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Name
                  </label>

                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="name"
                    type="text"
                    placeholder="Name"
                    // bind the input value to the client's email
                    defaultValue={data.data.name}
                  />
                </div>

                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="name"
                  >
                    Month-Year
                  </label>

                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="monthYear"
                    type="text"
                    placeholder="monthYear"
                    // bind the input value to the client's role
                    defaultValue={data.data.monthYear}
                  />
                </div>
              </CardContent>
            </CardHeader>
          </Card>
        </div>
        <div className=" flex lg:flex-1  gap-2 flex-wrap">
          <Card className="w-[200px] flex flex-col justify-between">
            <CardHeader>
              <CardDescription className="text-bold text-lg">
                Confirmation Count
              </CardDescription>
            </CardHeader>
            <CardContent>
              <CardTitle className="text-4xl pb-2">
                {data.data.confirmationCount}
              </CardTitle>
            </CardContent>
            <CardFooter className="flex justify-center items-center"></CardFooter>
          </Card>
          <Card className="w-[200px] flex flex-col justify-between">
            <CardHeader className=" ">
              <CardDescription className="text-bold text-lg">
                Sended Mail Count
              </CardDescription>
            </CardHeader>
            <CardContent>
              <CardTitle className="text-4xl pb-2">
                {data.data.sendedMailCount}
              </CardTitle>
            </CardContent>
            <CardFooter>
              <label></label>
            </CardFooter>
          </Card>
          <Card className="w-[200px] flex flex-col justify-between">
            <CardHeader className=" ">
              <CardDescription className="text-bold text-lg">
                Approved Count
              </CardDescription>
            </CardHeader>
            <CardContent>
              <CardTitle className="text-4xl pb-2">
                {data.data.approvedCount}
              </CardTitle>
            </CardContent>

            <CardFooter className="flex justify-center items-center"></CardFooter>
          </Card>
          <Card className="w-[200px]  flex flex-col justify-between ">
            <CardHeader>
              <CardDescription className="text-bold text-lg">
                Reject Count
              </CardDescription>
            </CardHeader>
            <CardContent className="flex ">
              <CardTitle className="text-4xl pb-2 ">
                {data.data.rejectCount}
              </CardTitle>
            </CardContent>
            <CardFooter className="flex  items-end"></CardFooter>
          </Card>
        </div>
      </div>
      {data.data.confirmationDetail.length > 0 && (
        <div className="flex flex-col gap-2 p-5 bg-slate-100">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Confirmation Detail
          </label>
          {data.data.confirmationDetail.map((commission, index) => (
            <ConfirmationDetailCard
              email={commission.email}
              name={commission.name}
              total={commission.total}
              remaining={commission.remaining}
              paid={commission.paid}
              status={commission.status}
              monthYear={commission.monthYear}
              isRejected={commission.isRejected}
              isApproved={commission.isApproved}
            />
          ))}
        </div>
      )}
    </AppLayout>
  );
}
