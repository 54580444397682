import { cn } from "@/lib/utils";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Label } from "../ui/label";
import { Badge } from "../ui/badge";

export function CommissionCard(props) {
  return (
    <Card className={props.isHorizontal && "flex justify-between items-center"}>
      <CardHeader>
        <CardTitle>{props.influencerName}</CardTitle>
        <CardDescription>{props.email}</CardDescription>
      </CardHeader>

      <CardContent
        className={
          props.isHorizontal
            ? "flex  w-full justify-evenly items-center p-0 "
            : "flex gap-2 items-center justify-between"
        }
      >
        <div className="flex  flex-col space-y-1.5">
          <Label>Invoice Id</Label>
          <p>{props.invoiceId}</p>
        </div>
        {props.isHorizontal && (
          <div className="flex flex-col space-y-1.5">
            <Label>Final Amount </Label>
            <span className="font-bold text-lg">{props.finalAmount}</span>
          </div>
        )}
        {!props.isHorizontal && (
          <div className="flex flex-col space-y-1.5">
            <Label>Final Amount </Label>
            <span className="font-bold text-lg">{props.finalAmount}</span>
          </div>
        )}
      </CardContent>
      <CardFooter
        className={cn(
          props.isHorizontal && "p-0 pr-1",
          "flex justify-between  "
        )}
      >
        <Badge
          className={cn(
            props.status == "Mail Sended" && "bg-gray-500",
            props.status == "Invoice Uploaded" && "bg-green-400",
            props.status == "Troubled" && "bg-yellow-700",
            props.status == "Change Requested" && "bg-red-700",
            props.status == "Approved" && "bg-blue-700",
            props.status == "Accounting" && "bg-purple-700",
            props.status == "Paid" && "bg-green-600",
            props.status == "LUCA" && "bg-orange-700"
          )}
        >
          {props.status}
        </Badge>
        <Button
          variant={"secondary"}
          type="submit"
          onClick={props.onDetailClick}
        >
          View Detail
        </Button>
      </CardFooter>
    </Card>
  );
}
