import React, { useEffect } from "react";
import AuthStore from "../../../stores/AuthStore";
import { AppLayout, DashboardLayout } from "../shared/layout";

export function HomeScreen() {
  useEffect(() => {});
  return (
    <div>
      <AppLayout title="Dashboard">
        <div>This page is for widgets</div>
      </AppLayout>
    </div>
  );
}
