import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
const PDFViewerCustom = ({ url }) => {
  const pdfURL = url;
  return (
    <div className="w-full border border-black">
      <Document file={pdfURL} className="w-full">
        <Page pageNumber={1} width={968} renderTextLayer={false} />
      </Document>
    </div>
  );
};
export default PDFViewerCustom;
