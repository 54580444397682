import api from "../api";

export const getAgencies = ({ page, pageSize, nameFilter, countryFilter }) => {
  return api.getApi().get(api.getBaseUrl() + "agencies", {
    params: { page, pageSize, nameFilter, countryFilter },
  });
};
export const getAgency = (id) => {
  return api.getApi().get(api.getBaseUrl() + "agencies/" + id);
};
export const createAgency = (data) => {
  return api.getApi().post(api.getBaseUrl() + "agencies", data);
};

export const updateAgency = (id, data) => {
  console.log("data", id);
  return api.getApi().put(api.getBaseUrl() + "agencies/" + id, data);
};
export const getProfile = () => {
  return api.getApi().get(api.getBaseUrl() + "profile");
};

export const deleteAgency = (id) => {
  return api.getApi().delete(api.getBaseUrl() + "agencies/" + id);
};
export const uploadAgencyWithExcel = (data) => {
  return api.getApi().post(api.getBaseUrl() + "agencies/uploadExcel", data);
};
