import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useQuery } from "@tanstack/react-query";
import { getClient } from "../data";
import SocialMediaIcons from "./social-media-icons";

export function ClientViewDialog({ isOpen, onClose, clientId }) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["client", clientId],
    queryFn: () => getClient(clientId),
    enabled: !!clientId,
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!data) return null;

  const client = data.data;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px] max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>{client.name}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h3 className="font-semibold">Email:</h3>
              <p>{client.email}</p>
            </div>
            <div>
              <h3 className="font-semibold">Phone:</h3>
              <p>{client.phoneNumber}</p>
            </div>
            <div>
              <h3 className="font-semibold">Billing Name:</h3>
              <p>{client.billingName}</p>
            </div>
            <div>
              <h3 className="font-semibold">Bank Name:</h3>
              <p>{client.bankName}</p>
            </div>
            <div>
              <h3 className="font-semibold">IBAN:</h3>
              <p>{client.IBAN}</p>
            </div>
            <div>
              <h3 className="font-semibold">SWIFT:</h3>
              <p>{client.SWIFT}</p>
            </div>
            <div>
              <h3 className="font-semibold">Luca ID:</h3>
              <p>{client.lucaId}</p>
            </div>
            <div>
              <h3 className="font-semibold">Luca Account Name:</h3>
              <p>{client.lucaAccountName}</p>
            </div>
            <div>
              <h3 className="font-semibold">Currency:</h3>
              <p>{client.currency}</p>
            </div>
            <div>
              <h3 className="font-semibold">Country:</h3>
              <p>{client.country}</p>
            </div>
            <div>
              <h3 className="font-semibold">New TV Account Manager:</h3>
              <p>{client.newTvAccountManager}</p>
            </div>
          </div>
          <div>
            <h3 className="font-semibold">Social Media Accounts:</h3>
            <SocialMediaIcons data={client.socialMediaAccounts} />
          </div>
          <div>
            <h3 className="font-semibold">Custom Data:</h3>
            {client.customData &&
              client.customData.map((item, index) => (
                <p key={index}>
                  {item.key}: {item.value}
                </p>
              ))}
          </div>
        </div>
        <Button onClick={onClose}>Close</Button>
      </DialogContent>
    </Dialog>
  );
}
