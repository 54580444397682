import { cn } from "@/lib/utils";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Label } from "../ui/label";
import SocialMediaIcons from "../social-media-icons";
export function ClientCard(props) {
  return (
    <Card className={props.isHorizontal && "flex justify-between items-center"}>
      <CardHeader>
        <CardTitle>{props.name}</CardTitle>
        <CardDescription>{props.email}</CardDescription>
      </CardHeader>

      <CardContent>
        {props.socialMediaAccounts && (
          <SocialMediaIcons data={props.socialMediaAccounts} />
        )}
      </CardContent>
      <CardFooter>
        <Button type="submit" onClick={props.editClick}>
          View Detail
        </Button>
      </CardFooter>
    </Card>
  );
}
