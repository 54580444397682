import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AdminLayout } from "../shared/layout";
import { deleteRole, getRoles, getUsers } from "../../../data";
import { ItemCard, UserCard } from "../../../components";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";
import AuthStore from "@/stores/AuthStore";

export function RoleListScreen() {
  const { toast } = useToast();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery({
    queryKey: ["roles"],
    queryFn: getRoles,
  });

  const pageSettings = {
    title: "Role List",
    actions: [
      {
        name: "Create New Role",
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          navigate("/admin/roles/add");
        },
      },
    ],
  };

  const mutation = useMutation({
    mutationKey: ["deleteRole"],
    mutationFn: (data) => {
      return deleteRole(data);
    },
    onSuccess: (response) => {
      toast({
        title: "Role Deleted",
        message: `Role ${response.data.name} has been deleted`,
        type: "success",
      });
      queryClient.invalidateQueries(["roles"]);
    },
  });

  const goToEdit = (id) => () => {
    navigate(`/admin/roles/${id}`);
  };

  const onDeleteRole = (id) => {
    mutation.mutate(id);
  };

  if (isLoading) {
    return (
      <AdminLayout>
        <div>Loading...</div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout
      title={pageSettings.title}
      actions={pageSettings.actions}
      loading={isLoading}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4 gap-4 ">
        {data.data.length ? (
          data.data.map((item) => (
            <ItemCard
              title={item.name}
              editClick={goToEdit(item.id)}
              isCanDelete={
                !item.isDefault && AuthStore.isHavePermission("ROLE_DELETE")
              }
              deleteClick={() => onDeleteRole(item.id)}
            />
          ))
        ) : (
          <div className=" bg-red-300">Not Found Roles</div>
        )}
      </div>
    </AdminLayout>
  );
}
