import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { createPayment } from "../../../data";

import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppLayout } from "../shared/layout";
import {
  CustomDataInput,
  FormInput,
  SocialMediaInput,
} from "../../../components";
import { uploadPaymentWithExcel } from "@/data/requests/payment-requests";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export function PaymentAddScreen() {
  const pageSettings = {
    title: "Create Payment",
    actions: [
      {
        name: "Go To List",
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          navigate("/payments");
        },
      },
    ],
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const mutation = useMutation({
    mutationKey: ["createPayment"],
    mutationFn: (data) => {
      return uploadPaymentWithExcel(data);
    },
    onSuccess: (response) => {
      navigate("/payments");
    },
  });

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   const formData = new FormData();
  //   formData.append("file", file);

  //   uploadPaymentWithExcel(formData).then(() => {
  //     fileSelect.current.value = "";
  //     queryClient.invalidateQueries("payments");
  //   });
  // };

  const onSubmit = async (data) => {
    const { name, excelFile, invoiceID, country } = data;

    console.log("data", data.mailBodyMessage);
    const formData = new FormData();
    formData.append("file", excelFile[0]);

    formData.append("name", name);
    formData.append("country", country);
    formData.append("invoiceId", data.invoiceId);
    formData.append("mailBodyMessage", data.mailBodyMessage);
    mutation.mutate(formData);
  };

  if (mutation.isPending) {
    return (
      <AppLayout>
        <div>Loading...</div>
      </AppLayout>
    );
  }

  return (
    <AppLayout title={pageSettings.title} actions={pageSettings.actions}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-2  ">
          <div className="grid w-1/2 gap-2">
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                Name
              </label>

              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                placeholder="Name"
                {...register("name", {
                  required: "Name is required",
                })}
              />
              {errors.name && <p>{errors.name.message}</p>}
            </div>
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Invoice ID
              </label>

              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="invoiceId"
                type="text"
                placeholder="Invoice ID (Year-Month)"
                {...register("invoiceId", {
                  required: "Invoice ID is required",
                })}
              />
              {errors.email && <p>{errors.email.message}</p>}
            </div>
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Country
              </label>

              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="country"
                type="text"
                placeholder="Country "
                {...register("country", {
                  required: "Country is required",
                })}
              />
              {errors.email && <p>{errors.email.message}</p>}
            </div>
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Mail Body Message (Not dynamic)
              </label>

              <ReactQuill
                theme="snow"
                onChange={(value) => setValue("mailBodyMessage", value)}
              />
              {errors.mailBodyMessage && (
                <p>{errors.mailBodyMessage.message}</p>
              )}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="host"
              >
                Excel File
              </label>

              <input
                id="excelFile"
                name="excelFile"
                type="file"
                {...register("excelFile", { required: true })}
              />

              {errors.excelFile && <p>{errors.excelFile.message}</p>}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Create Payment
          </button>
        </div>
      </form>
    </AppLayout>
  );
}
