import React from "react";
import { Input } from "./ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export const SearchView = ({
  searchParams,
  handleSearch,
  statusOptions = [],
  countryOptions = [],
}) => {
  return (
    <div>
      <div className="flex flex-row gap-2 mb-4">
        {Object.entries(searchParams).map(([key, value]) =>
          key === "status" && statusOptions.length > 0 ? (
            <Select
              key={key}
              value={value || undefined}
              onValueChange={(newValue) =>
                handleSearch({ key, value: newValue === "all" ? "" : newValue })
              }
            >
              <SelectTrigger>
                <SelectValue placeholder={`Filter by ${key}`} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All</SelectItem>
                {statusOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          ) : key === "country" ? (
            <Select
              key={key}
              value={value || undefined}
              onValueChange={(newValue) =>
                handleSearch({ key, value: newValue === "all" ? "" : newValue })
              }
            >
              <SelectTrigger>
                <SelectValue placeholder={`Filter by ${key}`} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All</SelectItem>
                {countryOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          ) : (
            <Input
              key={key}
              value={value}
              placeholder={key}
              onChange={(e) => handleSearch({ key, value: e.target.value })}
            />
          )
        )}
      </div>
    </div>
  );
};
