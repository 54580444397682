import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  getCommission,
  getCommissionUploadDetail,
  getPayment,
  postCommissionInvoiceUpload,
} from "../../../data";

import {
  Link,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppLayout } from "../shared/layout";
import { Button } from "@/components/ui/button";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";

export function CommissionInvoiceUploadScreen() {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const [screenLoading, setScreenLoading] = useState(false);

  const currentPath = useResolvedPath();

  const pathname = useResolvedPath().pathname.split("/")[2];
  const navigate = useNavigate();

  const { data, isLoading, error } = useQuery({
    queryKey: ["commissionDetail", pathname],

    queryFn: async () => await getCommissionUploadDetail(pathname),
    retry: 1,
  });

  const mutation = useMutation({
    mutationKey: ["uploadPaymentInvoice"],

    mutationFn: (data) => {
      return postCommissionInvoiceUpload(data);
    },
    onSuccess: (response) => {
      setScreenLoading(false);
      //navigate("/payments");
      if (response.status === 200) {
        queryClient.invalidateQueries(["uploadPaymentInvoice", pathname]);
      }
    },
    onError: (error) => {
      setScreenLoading(false);
      toast({
        title: "Error",
        description: "Something went wrong. Please try again later.",
        variant: "destructive",
      });
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const onSubmit = async (values) => {
    setScreenLoading(true);
    console.log(values);
    const { pdfFile } = values;

    if (pdfFile && pdfFile[0].type !== "application/pdf") {
      setScreenLoading(false);
      toast({
        title: "Invalid file type",
        description: "Please upload a PDF file only.",
        variant: "destructive",
      });
      return;
    }

    const formData = new FormData();
    formData.append("fileName", data.data.influencerName);
    formData.append("file", pdfFile[0]);

    mutation.mutate({
      path: pathname,
      data: formData,
      invoiceId: data.data.payment.invoiceId,
    });
  };

  if (!isLoading && !screenLoading) {
    if (data.data.country != null) {
      if (data.data.country == "Azerbaycan") {
        navigate("/invoiceManuel/" + pathname);
      }
    }
  }

  if (isLoading || screenLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Card className="w-[350px] ">
          <CardHeader>
            <CardTitle>Upload Invoice</CardTitle>
            <CardDescription>
              Please wait while we are processing your request
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-center">
              <div
                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Card className="w-[350px] ">
          <CardHeader>
            <CardTitle>Error</CardTitle>
            <CardDescription>
              Something went wrong. Please try again later. If the problem
              persists, please contact our support team.
            </CardDescription>
          </CardHeader>
        </Card>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center h-screen">
      {data.data.status == "Invoice Uploaded" ? (
        <div className="flex items-center justify-center h-screen">
          <Card className="w-[350px] ">
            <CardHeader>
              <CardTitle>Success</CardTitle>
              <CardDescription>
                Your invoice has been sent successfully. You can close this page
              </CardDescription>
            </CardHeader>
          </Card>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="w-[350px] ">
            <CardHeader>
              <CardTitle>Upload Invoice</CardTitle>
              <CardDescription>
                You can upload the invoice for the commission
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid w-full items-center gap-4">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="name">Email</Label>
                  <h1 id="name">{data.data.email}</h1>
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="name">Final Amount For Invoice</Label>
                  <h1 id="name">{data.data.finalAmount}</h1>
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="framework">Invoice Pdf (Pdf Only)</Label>

                  <Input
                    id="pdfFile"
                    type="file"
                    accept=".pdf"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file && file.type !== "application/pdf") {
                        toast({
                          title: "Invalid file type",
                          description: "Please select a PDF file only.",
                          variant: "destructive",
                        });
                        e.target.value = null; // Clear the input
                      } else {
                        setValue("pdfFile", e.target.files);
                      }
                    }}
                  />
                </div>
              </div>
            </CardContent>
            <CardFooter className="flex justify-between">
              <Button
                variant="outline"
                onClick={() => navigate("/invoiceManuelEN/" + pathname)}
              >
                Create Invoice
              </Button>
              <div></div>
              <Button type="submit">Upload</Button>
            </CardFooter>
          </Card>
        </form>
      )}
    </div>
  );
}
