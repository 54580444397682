import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AdminLayout } from "../shared/layout";
import { getUser, updateUser } from "../../../data";

import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Card } from "@/components/ui/card";
import { CardBox } from "../shared/CardBox";

export function UserUpdateScreen() {
  const queryClient = useQueryClient();
  const pageSettings = {
    title: "User Detail",
    actions: [
      {
        name: "Go To List",
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          navigate("/admin/users");
        },
      },
    ],
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { data, isLoading, error } = useQuery({
    queryKey: ["user", id],
    queryFn: async () => await getUser(id),
  });

  const mutation = useMutation({
    mutationKey: ["updateUser", id],
    mutationFn: (data) => {
      return updateUser(data.id, data.data);
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ["user", id] });
    },
  });
  const onSubmit = async (data) => {
    const { email, name, roles } = data;
    console.log("data a", email, name);
    mutation.mutate({ id: id, data: { email, name, roles } });
  };
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return (
      <AdminLayout>
        <div>Loading...</div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout
      title={pageSettings.title + " - " + id}
      actions={pageSettings.actions}
    >
      <CardBox>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>

            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="text"
              placeholder="Email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email format",
                },
              })}
              // bind the input value to the user's email
              defaultValue={data.data.email}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </div>

          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Name
            </label>

            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              placeholder="name"
              {...register("name", {})}
              // bind the input value to the user's role
              defaultValue={data.data.name}
            />
            {errors.name && <p>{errors.name.message}</p>}
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="permissions"
            >
              Permissions
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="roles"
              {...register("roles", {})}
              multiple
            >
              {data.data.allRoles.map((role) => (
                <option
                  key={role.id}
                  value={role.id}
                  selected={data.data.roles.some((x) => x.roleId == role.id)}
                >
                  {role.name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Update
            </button>
          </div>
        </form>
      </CardBox>
    </AdminLayout>
  );
}
