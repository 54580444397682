import { cn } from "@/lib/utils";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Label } from "../ui/label";
import SocialMediaIcons from "../social-media-icons";
export function AgencyCard(props) {
  return (
    <Card className="flex flex-col justify-between ">
      <CardHeader>
        <CardTitle>{props.name}</CardTitle>
        <CardDescription>{props.email}</CardDescription>
      </CardHeader>

      <CardContent>
        <div className="flex  flex-col space-y-1.5">
          <Label>Customer Count</Label>
          <p>{props.customerCount}</p>
        </div>
      </CardContent>
      <CardFooter className="flex justify-end">
        <Button type="submit" onClick={props.editClick}>
          View Detail
        </Button>
      </CardFooter>
    </Card>
  );
}
