import { cn } from "@/lib/utils";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Label } from "../ui/label";
import { Badge } from "../ui/badge";

export function ConfirmationDetailCard(props) {
  return (
    <Card className="flex  justify-between ">
      <CardHeader>
        <CardTitle>{props.name}</CardTitle>
        <CardDescription>{props.email}</CardDescription>
      </CardHeader>

      <CardContent className="flex  w-full justify-evenly items-center p-0">
        <div className="flex flex-col gap-2 ">
          <Label>Total</Label>
          <p>{props.total}</p>
        </div>
        <div className="flex flex-col gap-2 ">
          <Label>Remaining</Label>
          <p>{props.remaining}</p>
        </div>
        <div className="flex flex-col gap-2 ">
          <Label>Paid</Label>
          <p>{props.paid}</p>
        </div>
        <div className="flex flex-col gap-2">
          <Label>Is Reject</Label>
          {props.isRejected ? (
            <Badge>Evet</Badge>
          ) : (
            <Badge variant={"destructive"}>Hayır</Badge>
          )}
        </div>
        <div className="flex flex-col gap-2 ">
          <Label>Is Approve</Label>
          {props.isApproved ? (
            <Badge>Evet</Badge>
          ) : (
            <Badge variant={"destructive"}>Hayır</Badge>
          )}
        </div>
      </CardContent>
      <CardFooter className="p-0 pr-1 ">
        <Badge>{props.status}</Badge>
      </CardFooter>
    </Card>
  );
}
