import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AppLayout } from "../shared/layout";
import { InvoiceCard, SearchView } from "../../../components";
import { getInvoices, downloadInvoicesExcel } from "../../../data";
import ListView from "../../../components/list-view";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  approveInvoice,
  downloadFilteredInvoicesExcel,
  uploadInvoiceStatusExcel,
} from "@/data/requests/invoice-requests";

const STATUS_OPTIONS = [
  { value: "Approved", label: "Approved" },
  { value: "Change Requested", label: "Change Requested" },
  { value: "Troubled", label: "Troubled" },
  { value: "Invoice Uploaded", label: "Invoice Uploaded" },
  { value: "Mail Sended", label: "Mail Sended" },
  { value: "Accounting", label: "Accounting" },
  { value: "Auto Created Invoice", label: "Auto Created Invoice" },
  { value: "OCR Completed", label: "OCR Completed" },
  { value: "LUCA", label: "LUCA" },
  { value: "Paid", label: "Paid" },
  { value: "all", label: "All" },
];

export function InvoiceListScreen() {
  const queryClient = useQueryClient();

  const [pageSize] = useState(10);
  const [searchParams, setSearchParams] = useState({
    name: "",
    email: "",
    lucaId: "",
    status: "",
  });
  const [queryParams, setQueryParams] = useState({
    name: "",
    email: "",
    lucaId: "",
    status: "",
  });

  const navigate = useNavigate();
  const { id } = useParams();

  const [page, setPage] = useState(() => {
    const storedPage = localStorage.getItem(`invoiceListPage-${id}`);
    return storedPage ? parseInt(storedPage, 10) : 1;
  });
  useEffect(() => {
    localStorage.setItem(`invoiceListPage-${id}`, page.toString());
  }, [page, id]);

  const { data, isLoading } = useQuery({
    queryKey: ["invoices", id, page, pageSize, queryParams],
    queryFn: () =>
      getInvoices(id, {
        page,
        pageSize,
        nameFilter: queryParams.name,
        emailNameFilter: queryParams.email,
        lucaIdFilter: queryParams.lucaId,
        statusFilter: queryParams.status,
      }),
  });

  const downloadExcel = () => {
    downloadFilteredInvoicesExcel(id, {
      nameFilter: queryParams.name,
      emailNameFilter: queryParams.email,
      lucaIdFilter: queryParams.lucaId,
      statusFilter: queryParams.status,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "filtered_invoices.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  };

  const uploadExcel = (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      // Implement the API call to upload the Excel file
      // This should only update the status of existing invoices
      // You'll need to create a new API endpoint for this
      uploadInvoiceStatusExcel(id, formData).then(() => {
        queryClient.invalidateQueries(["invoices"]);
      });
    }
  };

  const approveInvoiceMutation = useMutation({
    mutationFn: approveInvoice,
    onSuccess: () => {
      queryClient.invalidateQueries(["invoices", id]);
    },
  });

  const handleUpdateStatus = (invoiceId, newStatus) => {
    approveInvoiceMutation.mutate({
      id: invoiceId,
      data: { status: newStatus },
    });
  };

  const handleSearch = (item) => {
    const newSearchParams = { ...searchParams, [item.key]: item.value };
    setSearchParams(newSearchParams);

    setTimeout(() => {
      setQueryParams(newSearchParams);
      setPage(1);
    }, 300);
  };

  const pageSettings = {
    title: "Invoice List",
    actions: [
      {
        name: "Download Excel",
        color: "bg-green-100 hover:bg-red-400 hover:text-black text-black-800",
        icon: "add-icon",
        onClick: downloadExcel,
      },
      {
        name: "Upload Status Excel",
        color: "bg-blue-100 hover:bg-blue-400 hover:text-black text-black-800",
        icon: "upload-icon",
        onClick: () => document.getElementById("uploadExcel").click(),
      },
      {
        name: "Back",
        color: "bg-red-100 hover:bg-red-400 hover:text-black text-red-800",
        icon: "add-icon",
        onClick: () => {
          navigate("/payments/" + id);
        },
      },
    ],
  };

  const columns = [
    {
      key: "name",
      label: "Name",
      accessor: (item) => item.commission?.client?.name || "",
    },
    {
      key: "email",
      label: "Email",
      accessor: (item) => item.commission?.email || "",
    },
    {
      key: "invoiceId",
      label: "Our Invoice Date",
      accessor: (item) => item.commission?.invoiceId || "",
    },
    {
      key: "invoiceId",
      label: "Invoice ID",
      accessor: (item) => item.invoiceId || "",
    },
    {
      key: "bankAccountHolder",
      label: "Bank Account Holder",
      accessor: (item) => item.bankAccountHolder || "",
    },
    {
      key: "lucaAccountName",
      label: "Luca Account Name",
      accessor: (item) => item.commission?.client?.lucaAccountName || "",
    },
    {
      key: "lucaId",
      label: "Luca ID",
      accessor: (item) => item.commission?.client?.lucaId || "",
    },
    {
      key: "commission.finalAmount",
      label: "Final Amount",
      accessor: (item) => item.commission?.finalAmount || "",
    },
    { key: "status", label: "Status" },
    {
      key: "actions",
      label: "Actions",
      render: (row) => (
        <div className="flex gap-2">
          <Button
            onClick={() => navigate(`/payments/${id}/invoices/${row.id}`)}
          >
            View Details
          </Button>
          {row.status !== "Paid" && (
            <Button
              variant="destructive"
              onClick={() => handleUpdateStatus(row.id, "Paid")}
              disabled={row.status === "Paid"}
            >
              Mark as Paid
            </Button>
          )}
          {row.status !== "Luca" && (
            <Button
              variant="secondary"
              onClick={() => handleUpdateStatus(row.id, "LUCA")}
              disabled={row.status === "LUCA"}
            >
              LUCA
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <AppLayout
      fullscreen={true}
      title={pageSettings.title}
      actions={pageSettings.actions}
      loading={isLoading}
    >
      <input
        type="file"
        id="uploadExcel"
        style={{ display: "none" }}
        onChange={uploadExcel}
        accept=".xlsx,.xls"
      />
      <SearchView
        searchParams={searchParams}
        handleSearch={handleSearch}
        statusOptions={STATUS_OPTIONS}
      />
      <ListView
        isLoading={isLoading}
        data={data?.data?.commissionDetails}
        columns={columns}
        totalItems={data?.data?.totalItems}
        enablePagination={true}
        itemsPerPage={pageSize}
        currentPage={page}
        onPageChange={setPage}
        renderGridItem={(item) => (
          <InvoiceCard
            key={item.id}
            name={item.commission.client?.name}
            email={item.email}
            invoiceId={item.commission.invoiceId}
            finalAmount={item.commission.finalAmount}
            status={item.status}
            onDetailClick={() =>
              navigate(`/payments/${id}/invoices/${item.id}`)
            }
          />
        )}
      />
    </AppLayout>
  );
}
