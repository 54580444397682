import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AdminLayout } from "../shared/layout";
import { getRole, getUser, updateRole, updateUser } from "../../../data";

import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CardBox } from "../shared/CardBox";

export function RoleUpdateScreen() {
  const queryClient = useQueryClient();
  const pageSettings = {
    title: "Role Detail",
    actions: [
      {
        name: "Go To List",
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          navigate("/admin/roles");
        },
      },
    ],
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { data, isLoading, error } = useQuery({
    queryKey: ["role", id],
    queryFn: async () => await getRole(id),
    retry: 0,
  });

  const mutation = useMutation({
    mutationKey: ["updateRple", id],
    mutationFn: (data) => {
      return updateRole(data.id, data.data);
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ["role", id] });
    },
  });
  const onSubmit = async (data) => {
    const { name, permissions } = data;

    mutation.mutate({ id: id, data: { name, permissions } });
  };
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return (
      <AdminLayout>
        <div>Loading...</div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout
      title={pageSettings.title + " - " + id}
      actions={pageSettings.actions}
    >
      <CardBox>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Name
            </label>

            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              placeholder="name"
              {...register("name", {})}
              // bind the input value to the user's role
              defaultValue={data.data.name}
            />
            {errors.name && <p>{errors.name.message}</p>}
          </div>

          {/* get all permission and role permission is have select get all permission and role permission is have select */}
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="permissions"
            >
              Permissions
            </label>
            <select
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="permissions"
              {...register("permissions", {})}
              multiple
            >
              {data.data.allPermissions.map((permission) => (
                <option
                  key={permission.id}
                  value={permission.id}
                  selected={data.data.permissions.some(
                    (x) => x.permissionId == permission.id
                  )}
                >
                  {permission.name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Update
            </button>
          </div>
        </form>
      </CardBox>
    </AdminLayout>
  );
}
