import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { createConfirmation } from "../../../data";

import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppLayout } from "../shared/layout";
import {
  CustomDataInput,
  FormInput,
  SocialMediaInput,
} from "../../../components";
import { uploadConfirmationWithExcel } from "@/data/requests/confirmation-requests";

export function ConfirmationAddScreen() {
  const pageSettings = {
    title: "Create Confirmation",
    actions: [
      {
        name: "Go To List",
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          navigate("/confirmations");
        },
      },
    ],
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const mutation = useMutation({
    mutationKey: ["createConfirmation"],
    mutationFn: (data) => {
      return uploadConfirmationWithExcel(data);
    },
    onSuccess: (response) => {
      navigate("/confirmations");
    },
  });

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   const formData = new FormData();
  //   formData.append("file", file);

  //   uploadConfirmationWithExcel(formData).then(() => {
  //     fileSelect.current.value = "";
  //     queryClient.invalidateQueries("confirmations");
  //   });
  // };

  const onSubmit = async (data) => {
    const { name, excelFile, monthYear } = data;

    const formData = new FormData();
    formData.append("file", excelFile[0]);

    formData.append("name", name);
    formData.append("monthYear", data.monthYear);
    mutation.mutate(formData);
  };

  return (
    <AppLayout title={pageSettings.title} actions={pageSettings.actions}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-2  ">
          <div className="grid w-1/2 gap-2">
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                Name
              </label>

              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                placeholder="Name"
                {...register("name", {
                  required: "Name is required",
                })}
              />
              {errors.name && <p>{errors.name.message}</p>}
            </div>
            <div>
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Month-Year
              </label>

              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="monthYear"
                type="text"
                placeholder="Month Year (Month-Year)"
                {...register("monthYear", {
                  required: "Month Year is required",
                })}
              />
              {errors.email && <p>{errors.email.message}</p>}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="host"
              >
                Excel File
              </label>

              <input
                id="excelFile"
                name="excelFile"
                type="file"
                {...register("excelFile", { required: true })}
              />

              {errors.excelFile && <p>{errors.excelFile.message}</p>}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Create Confirmation
          </button>
        </div>
      </form>
    </AppLayout>
  );
}
