import React from "react";
import { NavbarAdmin } from "../../../components";
import { Link } from "react-router-dom";

export const AdminLayout = (props) => {
  if (props.loading) {
    <div>Loading...</div>;
  }

  return (
    <div className="flex min-h-screen">
      <NavbarAdmin />

      <main className="flex-1 bg-gray-100 dark:bg-gray-800 p-8">
        <header className=" mx-auto p-4  lg:flex lg:items-center lg:justify-between border-b-2 mb-2 ">
          <div className="flex-1 min-w-0">
            <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-50">
              {props.title}
            </h1>
            <p className="text-gray-500 dark:text-gray-400">
              {props.description}
            </p>
          </div>
          <div className="mt-5 flex lg:mt-0 lg:ml-4">
            {props.actions != null
              ? props.actions.map((item) => (
                  <button
                    key={item.name}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={item.onClick}
                  >
                    {item.name}
                  </button>
                ))
              : ""}
          </div>
        </header>
        <div className=" mx-auto">{props.children}</div>
      </main>
    </div>
  );
};
