import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AppLayout } from "../shared/layout";
import { PaymentCard, SearchView } from "../../../components";
import { getPayments } from "../../../data";
import ListView from "../../../components/list-view";
import { Button } from "@/components/ui/button";
import { countryOptions } from "@/utils/country-options";
export function PaymentListScreen() {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [searchParams, setSearchParams] = useState({
    name: "",
    invoiceId: "",
    country: "",
  });
  const [queryParams, setQueryParams] = useState({
    name: "",
    invoiceId: "",
    country: "",
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const searchTimeoutRef = useRef(null);
  const { data, isLoading } = useQuery({
    queryKey: ["payments", page, pageSize, queryParams],
    queryFn: () =>
      getPayments({
        page,
        pageSize,
        nameFilter: queryParams.name,
        invoiceIdFilter: queryParams.invoiceId,
        countryFilter: queryParams.country,
      }),
  });

  const pageSettings = {
    title: "Payment List",
    actions: [
      {
        name: "Create New Payment",
        color: "bg-blue-500 hover:bg-blue-700 text-white",
        icon: "add-icon",
        onClick: () => {
          navigate("/payments/add");
        },
      },
    ],
  };

  const goToDetail = (id) => () => {
    navigate(`/payments/${id}`);
  };

  const handleSearch = (item) => {
    const newSearchParams = { ...searchParams, [item.key]: item.value };
    setSearchParams(newSearchParams);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      setQueryParams(newSearchParams);
      setPage(1);
    }, 300);
  };

  const columns = [
    { key: "name", label: "Name" },
    { key: "invoiceId", label: "Invoice ID" },
    {
      key: "actions",
      label: "Actions",
      render: (row) => (
        <div>
          <Button onClick={goToDetail(row.id)}>View Details</Button>
        </div>
      ),
    },
  ];

  return (
    <AppLayout
      title={pageSettings.title}
      actions={pageSettings.actions}
      loading={isLoading}
    >
      <SearchView
        searchParams={searchParams}
        handleSearch={handleSearch}
        countryOptions={countryOptions}
      />
      <ListView
        isLoading={isLoading}
        searchParams={searchParams}
        handleSearch={handleSearch}
        data={data?.data.payments}
        columns={columns}
        totalItems={data?.data.totalItems}
        enablePagination={true}
        itemsPerPage={pageSize}
        currentPage={page}
        onPageChange={setPage}
        renderGridItem={(item) => (
          <PaymentCard
            key={item.id}
            name={item.name}
            invoiceId={item.invoiceId}
            amount={item.amount}
            status={item.status}
            editClick={goToDetail(item.id)}
          />
        )}
      />
    </AppLayout>
  );
}
