import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { getClient, updateClient } from "../../../data";

import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppLayout } from "../shared/layout";
import {
  CommissionCard,
  CustomDataInput,
  ItemCard,
  SocialMediaInput,
} from "@/components";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { useEffect } from "react";
import SocialMediaIcons from "@/components/social-media-icons";
import { useToast } from "@/components/ui/use-toast";

export function ClientUpdateScreen() {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const pageSettings = {
    title: "Client Detail",
    actions: [
      {
        name: "Go To List",
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          navigate("/clients");
        },
      },
    ],
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const watchIsHaveAgency = watch("isHaveAgency");
  const { data, isLoading, error } = useQuery({
    queryKey: ["client", id],
    queryFn: async () => await getClient(id),
  });

  const mutation = useMutation({
    mutationKey: ["updateClient", id],
    mutationFn: (data) => {
      return updateClient(data.id, data.data);
    },
    onSuccess: (response) => {
      toast({
        title: "Client Updated",
        message: `Client ${response.data.name} has been updated`,
        type: "success",
      });
      queryClient.invalidateQueries({ queryKey: ["client", id] });
    },
  });
  const onSubmit = async (data) => {
    // if socialMediaaccount is platform and accountId empty error

    console.log("socialMediaAccounts", data.socialMediaAccounts);
    if (data.socialMediaAccounts) {
      var isHaveEmptySocialMediaAccount = false;
      data.socialMediaAccounts.map((socialMediaAccount) => {
        if (
          socialMediaAccount.platform === "" ||
          socialMediaAccount.accountId === ""
        ) {
          isHaveEmptySocialMediaAccount = true;
        }
      });

      if (isHaveEmptySocialMediaAccount) {
        alert("Please fill all social media account fields");
        return;
      }
    }

    if (data.customData) {
      var isHaveEmptyCustomData = false;
      data.customData.map((customData) => {
        if (customData.key === "" || customData.value === "") {
          isHaveEmptyCustomData = true;
        }
      });
      if (isHaveEmptyCustomData) {
        alert("Please fill all custom data fields");
        return;
      }
    }

    mutation.mutate({
      id: id,
      data: {
        email: data.email,
        name: data.name,
        phoneNumber: data.phoneNumber,
        billingName: data.billingName,
        bankName: data.bankName,
        IBAN: data.IBAN,
        SWIFT: data.SWIFT,
        customData: data.customData,
        isHaveAgency: data.isHaveAgency,
        manager: data.manager,
        socialMediaAccounts: data.socialMediaAccounts,
        agencyId: data.agencyId,

        lucaId: data.lucaId,
        lucaAccountName: data.lucaAccountName,
        currency: data.currency,
        country: data.country,
        newTvAccountManager: data.newTvAccountManager,
      },
    });
  };
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return (
      <AppLayout>
        <div>Loading...</div>
      </AppLayout>
    );
  }

  return (
    <AppLayout
      title={pageSettings.title + " - " + data.data.name}
      actions={pageSettings.actions}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-2  ">
          <div className="flex gap-2 ">
            <div className="flex w-1/2 ">
              <div className="flex flex-col w-full gap-3 ">
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="name"
                  >
                    Name
                  </label>

                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="name"
                    type="text"
                    placeholder="Name"
                    {...register("name", {
                      required: "Name is required",
                    })}
                    defaultValue={data.data.name}
                  />
                  {errors.name && <p>{errors.name.message}</p>}
                </div>
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Email
                  </label>

                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    type="text"
                    placeholder="Name"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Invalid email format",
                      },
                    })}
                    defaultValue={data.data.email}
                  />
                  {errors.email && <p>{errors.email.message}</p>}
                </div>
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="phoneNumber"
                  >
                    Phone Number
                  </label>

                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="phoneNumber"
                    type="text"
                    placeholder="Phone Number"
                    {...register("phoneNumber", {
                      required: "Phone Number is required",
                    })}
                    defaultValue={data.data.phoneNumber}
                  />
                  {errors.phoneNumber && <p>{errors.phoneNumber.message}</p>}
                </div>
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="billingName"
                  >
                    Billing Name
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="billingName"
                    type="text"
                    placeholder="Billing Name"
                    {...register("billingName", {
                      required: "Billing Name is required",
                    })}
                    defaultValue={data.data.billingName}
                  />
                  {errors.billingName && <p>{errors.billingName.message}</p>}
                </div>
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="bankName"
                  >
                    Bank Name
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="bankName"
                    type="text"
                    placeholder="Bank Name"
                    {...register("bankName", {
                      required: "Bank Name is required",
                    })}
                    defaultValue={data.data.bankName}
                  />
                  {errors.bankName && <p>{errors.bankName.message}</p>}
                </div>
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="lucaId"
                  >
                    Luca ID
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="lucaId"
                    type="text"
                    placeholder="Luca ID"
                    {...register("lucaId")}
                    defaultValue={data.data.lucaId}
                  />
                  {errors.lucaId && <p>{errors.lucaId.message}</p>}
                </div>
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="lucaAccountName"
                  >
                    Luca Account Name
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="lucaAccountName"
                    type="text"
                    placeholder="Luca Account Name"
                    {...register("lucaAccountName")}
                    defaultValue={data.data.lucaAccountName}
                  />
                  {errors.lucaAccountName && (
                    <p>{errors.lucaAccountName.message}</p>
                  )}
                </div>
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="currency"
                  >
                    Currency
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="currency"
                    type="text"
                    placeholder="Currency"
                    {...register("currency", {
                      required: "Currency  is required",
                    })}
                    defaultValue={data.data.currency}
                  />
                  {errors.currency && <p>{errors.currency.message}</p>}
                </div>
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="country"
                  >
                    Country
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="country"
                    type="text"
                    placeholder="Country"
                    {...register("country", {
                      required: "Country  is required",
                    })}
                    defaultValue={data.data.country}
                  />
                  {errors.country && <p>{errors.country.message}</p>}
                </div>
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="newTvAccountManager"
                  >
                    New Tv Manager
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="newTvAccountManager"
                    type="text"
                    placeholder="New Tv Account Manager"
                    {...register("newTvAccountManager", {
                      required: "New Tv Account Manager  is required",
                    })}
                    defaultValue={data.data.newTvAccountManager}
                  />
                  {errors.newTvAccountManager && (
                    <p>{errors.newTvAccountManager.message}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="grid w-1/2 ">
              <div className="flex flex-col w-full gap-3">
                <div className="flex justify-between">
                  <div className="flex items-center gap-2">
                    <Checkbox
                      id="isHaveAgency"
                      name="isHaveAgency"
                      onCheckedChange={(value) => {
                        console.log("data", value);

                        if (!value) {
                          setValue("agencyId", "");
                          data.data.agencyId = "";
                        }

                        setValue("isHaveAgency", value);
                      }}
                      defaultChecked={data.data.agencyId ? true : false}
                    />

                    <label
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      htmlFor="isHaveAgency"
                    >
                      Is Have Agency
                    </label>
                  </div>

                  {(watchIsHaveAgency || data.data.agencyId) && (
                    <div>
                      <Select
                        onValueChange={(value) => setValue("agencyId", value)}
                        {...(data.data.agencyId && {
                          defaultValue: data.data.agencyId.toString(),
                        })}
                        {...register("agencyId")}
                      >
                        <SelectTrigger className="w-[300px]">
                          <SelectValue placeholder="Select a Manager" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            <SelectLabel>Managers</SelectLabel>

                            {data.data.agencies.map((agency) => (
                              <SelectItem
                                value={agency.id.toString()}
                                key={agency.id}
                              >
                                {agency.name}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </div>
                  )}
                </div>

                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="IBAN"
                  >
                    IBAN
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="IBAN"
                    type="text"
                    placeholder="IBAN"
                    {...register("IBAN", {
                      required: "IBAN is required",
                    })}
                    defaultValue={data.data.IBAN}
                  />
                  {errors.IBAN && <p>{errors.IBAN.message}</p>}
                </div>

                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="SWIFT"
                  >
                    SWIFT
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="SWIFT"
                    type="text"
                    placeholder="SWIFT"
                    {...register("SWIFT", {
                      required: "SWIFT is required",
                    })}
                    defaultValue={data.data.SWIFT}
                  />
                  {errors.SWIFT && <p>{errors.SWIFT.message}</p>}
                </div>

                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="roles"
                  >
                    Social Medias
                  </label>
                  <SocialMediaIcons data={data.data.socialMediaAccounts} />
                </div>
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="socialMediaAccounts"
                  >
                    Social Media Accounts
                  </label>
                  <SocialMediaInput
                    value={data.data.socialMediaAccounts}
                    onDataChanged={(data) => {
                      console.log("data", data);

                      setValue("socialMediaAccounts", data);
                    }}
                  />

                  {errors.socialMediaAccounts && (
                    <p>{errors.socialMediaAccounts.message}</p>
                  )}
                </div>

                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="customData"
                  >
                    Custom Data
                  </label>

                  <CustomDataInput
                    value={data.data.customData}
                    onDataChanged={(data) => {
                      setValue("customData", data);
                    }}
                  />

                  {errors.customData && <p>{errors.customData.message}</p>}
                </div>
              </div>
            </div>
          </div>

          <div className="mh-10">
            <div className="flex items-center justify-between">
              <Button type="submit">Update</Button>
            </div>
          </div>
        </div>
      </form>

      {data.data.commissions.length > 0 && (
        <div className="flex flex-col gap-2 p-5 bg-slate-100">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Commissions
          </label>
          {data.data.commissions.map((commission, index) => (
            <CommissionCard
              isHorizontal={true}
              email={commission.email}
              finalAmount={commission.finalAmount}
              invoiceId={commission.invoiceId}
              influencerName={commission.influencerName}
              onDetailClick={() => {
                navigate(`/commissions/${commission.id}`);
              }}
            />
          ))}
        </div>
      )}
    </AppLayout>
  );
}
