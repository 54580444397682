import api from "../api";

export const getGptPrompts = (data) => {
  return api.getApi().get(api.getBaseUrl() + "gptprompts");
};
export const addGptPrompt = (data) => {
  return api.getApi().post(api.getBaseUrl() + "gptprompts", data);
};
export const updateGptPrompt = (id, data) => {
  return api.getApi().put(api.getBaseUrl() + "gptprompts/" + id, data);
};
export const getGptPrompt = (id) => {
  return api.getApi().get(api.getBaseUrl() + "gptprompts/" + id);
};

export const deleteGptPrompt = (id) => {
  return api.getApi().delete(api.getBaseUrl() + "gptprompts/" + id);
};
