import { Link, useNavigate } from "react-router-dom";
import { useObserver } from "mobx-react";
import AuthStore from "../../stores/AuthStore";
import { useQueryClient } from "@tanstack/react-query";

export function NavbarAdmin() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const adminRoutesMenu = [
    {
      displayName: "Users",
      path: "/admin/users",
      isHavePermission: AuthStore.isHavePermission("Permission_UserRead"),
    },
    {
      displayName: "Roles",
      path: "/admin/roles",
      isHavePermission: AuthStore.isHavePermission("Permission_RoleRead"),
    },
    {
      displayName: "Gpt Prompts",
      path: "/admin/gptprompts",
      isHavePermission: AuthStore.isHavePermission("Permission_SuperAdmin"),
    },
    {
      displayName: "Settings",
      path: "/admin/settings",
      isHavePermission: AuthStore.isHavePermission("Permission_SuperAdmin"),
    },
  ];
  const logout = () => {
    // localstorage  must be clear in here
    queryClient.clear();
    AuthStore.logout();
    navigate("/");
  };
  return useObserver(() => (
    <nav className="bg-gray-900 text-gray-400 w-64 p-6 border-r border-gray-800">
      <div className="flex  flex-col  justify-between h-full">
        <div className="space-y-6">
          <Link
            className="flex items-center gap-2 text-lg font-semibold text-gray-50"
            href="#"
          >
            <span>Newtv Portal</span>
          </Link>
          <div className="space-y-4">
            {adminRoutesMenu.map(
              (route) =>
                route.isHavePermission && (
                  <Link
                    className="flex items-center gap-2 hover:text-gray-50 transition-colors"
                    to={route.path}
                    key={route.path}
                  >
                    {route.displayName}
                  </Link>
                )
            )}
          </div>
        </div>

        <div className="space-y-4">
          <Link
            className="flex items-center gap-2 hover:text-gray-50 transition-colors"
            to="/dashboard"
            href="#"
          >
            <span>Exit Admin</span>
          </Link>
          <Link
            className="flex items-center gap-2 hover:text-gray-50 transition-colors"
            onClick={logout}
            href="#"
          >
            <span>Logout</span>
          </Link>
        </div>
      </div>
    </nav>
  ));
}
