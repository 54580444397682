import api from "../api";

const getWaitingPageStatus = () => {
  return api.getApi().get(api.getBaseUrl() + "settings/waiting-page-status");
};
const updateWaitingPageStatus = (status) => {
  return api.getApi().post(api.getBaseUrl() + "settings/waiting-page-status", {
    status,
  });
};

export { getWaitingPageStatus, updateWaitingPageStatus };
