import React from "react";
import "./global.css";
import { BrowserRouter as Router } from "react-router-dom";
import { BaseRouter } from "./navigations";
import { Toaster } from "@/components/ui/toaster";

import {
  useQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        return (
          error?.response?.status !== 403 && error?.response?.status !== 401
        ); // Adjust accordingly based on your error object
      },
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <BaseRouter />
        <Toaster />
      </Router>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
