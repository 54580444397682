import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AppLayout } from "../shared/layout";
import { CommissionCard, SearchView } from "../../../components";
import {
  getCommissions,
  postCommissionInvoiceUploadManuel,
} from "../../../data";
import ListView from "../../../components/list-view";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const STATUS_OPTIONS = [
  { value: "Approved", label: "Approved" },
  { value: "Change Requested", label: "Change Requested" },
  { value: "Troubled", label: "Troubled" },
  { value: "Invoice Uploaded", label: "Invoice Uploaded" },
  { value: "Mail Sended", label: "Mail Sended" },
  { value: "Accounting", label: "Accounting" },
  { value: "Auto Created Invoice", label: "Auto Created Invoice" },
  { value: "OCR Completed", label: "OCR Completed" },
  { value: "LUCA", label: "LUCA" },
  { value: "Paid", label: "Paid" },
  { value: "Admin Uploaded", label: "Admin Uploaded" },
];

export function CommissionListScreen() {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [searchParams, setSearchParams] = useState({
    name: "",
    email: "",
    lucaId: "",
    status: "",
  });
  const [queryParams, setQueryParams] = useState({
    name: "",
    email: "",
    lucaId: "",
    status: "",
  });

  const [isPending, setIsPending] = useState(false);
  const [isCanUploadManuel, setIsCanUploadManual] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: ["commissions", id, page, pageSize, queryParams],
    queryFn: () =>
      getCommissions(id, {
        page,
        pageSize,
        nameFilter: queryParams.name,
        emailNameFilter: queryParams.email,
        lucaIdFilter: queryParams.lucaId,
        statusFilter: queryParams.status,
      }),
  });

  const pageSettings = {
    title: "Commission List",
    actions: [
      {
        name: "Back",
        color: "bg-red-100 hover:bg-red-400 hover:text-black text-red-800",
        icon: "add-icon",
        onClick: () => {
          navigate("/payments/" + id);
        },
      },
      {
        name: isCanUploadManuel
          ? "Manuel Upload Pdf DeActive"
          : "Manuel Upload Pdf Active",
        color: isCanUploadManuel
          ? "bg-red-100 hover:bg-red-400 hover:text-black text-red-800"
          : "bg-blue-100 hover:bg-blue-400 hover:text-black text-black-800",
        icon: "add-icon",
        onClick: () => {
          setIsCanUploadManual(!isCanUploadManuel);
        },
      },
    ],
  };

  const goToCommission = (commissionId) => () => {
    window.location.href = `/payments/${id}/commissions/${commissionId}`;
  };

  const uploadInvoice = async (e) => {
    e.preventDefault();
    const form = e.target;
    const data = {
      invoiceId: form.invoiceId.value,
      commissionId: form.commissionId.value,
      influencerName: form.influencerName.value,
      pdfFile: form.pdfFile.files[0],
    };
    const formDataAg = new FormData();
    formDataAg.append("fileName", data.influencerName);
    formDataAg.append("file", data.pdfFile);

    data.data = formDataAg;
    setIsPending(true);
    await postCommissionInvoiceUploadManuel(data).then(() => {
      setIsPending(false);
      queryClient.invalidateQueries({ queryKey: ["commissions"] });
    });
  };

  const handleSearch = (item) => {
    const newSearchParams = { ...searchParams, [item.key]: item.value };
    setSearchParams(newSearchParams);

    setTimeout(() => {
      setQueryParams(newSearchParams);
      setPage(1);
    }, 300);
  };

  const columns = [
    { key: "influencerName", label: "Influencer Name" },
    { key: "email", label: "Email" },
    { key: "finalAmount", label: "Final Amount" },
    { key: "invoiceId", label: "Invoice ID" },
    { key: "status", label: "Status" },
    {
      key: "actions",
      label: "Actions",
      render: (row) => (
        <div>
          <Button onClick={goToCommission(row.id)}>View Details</Button>
          {isCanUploadManuel && (
            <form onSubmit={uploadInvoice}>
              <Input id="pdfFile" name="pdfFile" type="file" />
              <Button variant="secondary" type="submit">
                Upload Invoice Pdf
              </Button>
              <input type="hidden" name="invoiceId" value={row.invoiceId} />
              <input type="hidden" name="commissionId" value={row.id} />
              <input
                type="hidden"
                name="influencerName"
                value={row.influencerName}
              />
            </form>
          )}
        </div>
      ),
    },
  ];

  return (
    <AppLayout
      title={pageSettings.title}
      actions={pageSettings.actions}
      loading={isLoading || isPending}
    >
      <SearchView
        searchParams={searchParams}
        handleSearch={handleSearch}
        statusOptions={STATUS_OPTIONS}
      />
      <ListView
        isLoading={isLoading}
        data={data?.data?.commissions}
        columns={columns}
        totalItems={data?.data?.totalItems}
        enablePagination={true}
        itemsPerPage={pageSize}
        currentPage={page}
        onPageChange={setPage}
        renderGridItem={(item) => (
          <div
            className={
              isCanUploadManuel ? "flex flex-col bg-yellow-100 p-2" : ""
            }
            key={item.id}
          >
            <CommissionCard
              email={item.email}
              finalAmount={item.finalAmount}
              invoiceId={item.invoiceId}
              influencerName={item.influencerName}
              status={item.status}
              onDetailClick={goToCommission(item.id)}
            />
            {isCanUploadManuel && (
              <form onSubmit={uploadInvoice}>
                <div className="flex justify-center items-center mt-3">
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="framework">Invoice Pdf</Label>
                    <Input id="pdfFile" name="pdfFile" type="file" />
                    <Button variant="secondary" type="submit">
                      Upload Invoice Pdf
                    </Button>
                  </div>
                </div>
                <input type="hidden" name="invoiceId" value={item.invoiceId} />
                <input type="hidden" name="commissionId" value={item.id} />
                <input
                  type="hidden"
                  name="influencerName"
                  value={item.influencerName}
                />
              </form>
            )}
          </div>
        )}
      />
    </AppLayout>
  );
}
