import { useCallback } from 'react';
import { useObserver } from 'mobx-react';
import viewStore from '../stores/ViewStore';

export const useTableView = () => {
  const setTableView = (value) => {
    console.log("setTableView", value);
    viewStore.setTableView(value);
  };

  return useObserver(() => ({
    isTableView: viewStore.getIsTableView,
    setTableView,
    }));
};