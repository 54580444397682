import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { getPayment, sendInvoiceMails } from "../../../data";

import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppLayout } from "../shared/layout";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import {
  downloadAllInvoices,
  sendReminderInvoiceMails,
} from "@/data/requests/payment-requests";
import { Download } from "lucide-react";
import { Label } from "@/components/ui/label";
import ReactQuill from "react-quill";

export function PaymentDetailScreen() {
  const queryClient = useQueryClient();

  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading, error } = useQuery({
    queryKey: ["payment", id],
    queryFn: async () => await getPayment(id),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const mutation = useMutation({
    mutationFn: async ({ paymentId }) => {
      await sendInvoiceMails(paymentId);
    },
    onSuccess: () => {
      toast("Invoice mails sent successfully", { type: "success" });
      queryClient.invalidateQueries({ queryKey: ["payment", id] });
    },
  });

  const mutationReminder = useMutation({
    mutationFn: async (data) => {
      await sendReminderInvoiceMails(data);
    },
    onSuccess: () => {
      toast("Invoice mails sent successfully", { type: "success" });
      queryClient.invalidateQueries({ queryKey: ["payment", id] });
    },
  });

  const pageSettings = {
    title: "Payment Detail",
    actions: [
      {
        name: "Go To List",
        color: "primary",
        icon: "add-icon",
        onClick: () => {
          navigate("/payments");
        },
      },
      {
        name: "Download All Pdf",
        color: "primary",
        icon: "add-icon",
        onClick: async () => {
          await downloadAllInvoices(id).then((res) => {
            debugger;
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "download.zip"); // or any other extension
            document.body.appendChild(link);
            link.click();

            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
          });
          //mutationDownloadPdf.mutate({ paymentId: id });
        },
      },
      {
        name: "Send Invoice Mails",
        color: "primary",
        icon: "add-icon",
        onClick: () => {
          sendMails();
        },
      },
    ],
  };

  const sendMails = () => {
    if (data.data.sendedMailCount == data.data.commissionCount) {
      toast({
        title: "Invoice Mails Already Sent",
        message: "",
        type: "error",
      });
      return;
    }
    mutation.mutate({ paymentId: id });
  };

  const onSubmit = async () => {
    if (data.data.sendedMailCount == data.data.uploadedInvoiceCount) {
      toast({
        title: "Invoice Mails Already Sent",
        message: "",
        type: "error",
      });
      return;
    }

    mutationReminder.mutate({
      paymentId: id,
      data: {
        mailBodyMessage: getValues("mailBodyMessage"),
      },
    });
  };

  if (isLoading) {
    return (
      <AppLayout>
        <div>Loading...</div>
      </AppLayout>
    );
  }
  if (mutation.isPending || mutationReminder.isPending) {
    return (
      <AppLayout>
        <div>Mailler gonderiliyor...</div>
      </AppLayout>
    );
  }

  return (
    <AppLayout
      title={pageSettings.title + " - " + id}
      actions={pageSettings.actions}
      isLoading={isLoading}
    >
      <div className="flex justify-between gap-3 flex-wrap">
        <div className="flex-1">
          <Card>
            <CardHeader className=" ">
              <CardTitle className="text-xl pb-2"> Payment Detail</CardTitle>
              <CardContent className="p-0" s>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Name
                  </label>

                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="name"
                    type="text"
                    placeholder="Name"
                    disabled
                    // bind the input value to the client's email
                    defaultValue={data.data.name}
                  />
                </div>

                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="name"
                  >
                    Invoice ID
                  </label>

                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="invoiceId"
                    type="text"
                    disabled
                    placeholder="invoiceId"
                    // bind the input value to the client's role
                    defaultValue={data.data.invoiceId}
                  />
                </div>
                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="name"
                  >
                    Country
                  </label>

                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="invoiceId"
                    disabled
                    type="text"
                    placeholder="Country"
                    // bind the input value to the client's role
                    defaultValue={data.data.commissions[0]?.country}
                  />
                </div>

                <div>
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button variant="destructive">Send Reminder Mail</Button>
                    </DialogTrigger>
                    <DialogContent className="sm:max-w-[425px]">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <DialogHeader>
                          <DialogTitle>Send Reminder Mail</DialogTitle>
                          <DialogDescription>
                            Write a message to the client to inform them about
                            the reminder request.
                          </DialogDescription>
                        </DialogHeader>
                        <div className="grid gap-4 py-4">
                          <div className="grid  items-center">
                            <Label htmlFor="name" className="text-right">
                              Message
                            </Label>
                            <ReactQuill
                              theme="snow"
                              onChange={(value) =>
                                setValue("mailBodyMessage", value)
                              }
                            />
                          </div>
                        </div>
                        <DialogFooter>
                          <button type="submit">Send Reminder Mail</button>
                        </DialogFooter>
                      </form>
                    </DialogContent>
                  </Dialog>
                </div>
              </CardContent>
            </CardHeader>
          </Card>
        </div>
        <div className=" flex lg:flex-1  gap-2 flex-wrap">
          <Card className="w-[200px] flex flex-col justify-between">
            <CardHeader>
              <CardDescription className="text-bold text-lg">
                Commission Count
              </CardDescription>
            </CardHeader>
            <CardContent>
              <CardTitle className="text-4xl pb-2">
                {data.data.commissionCount}
              </CardTitle>
            </CardContent>
            <CardFooter className="flex justify-center items-center">
              <Button>
                <Link to={`/payments/${id}/commissions`}>View Commissions</Link>
              </Button>
            </CardFooter>
          </Card>
          <Card className="w-[200px] flex flex-col justify-between">
            <CardHeader className=" ">
              <CardDescription className="text-bold text-lg">
                Sended Mail Count
              </CardDescription>
            </CardHeader>
            <CardContent>
              <CardTitle className="text-4xl pb-2">
                {data.data.sendedMailCount}
              </CardTitle>
            </CardContent>
            <CardFooter>
              <label></label>
            </CardFooter>
          </Card>
          <Card className="w-[200px] flex flex-col justify-between">
            <CardHeader className=" ">
              <CardDescription className="text-bold text-lg">
                Uploaded Invoice Count
              </CardDescription>
            </CardHeader>
            <CardContent>
              <CardTitle className="text-4xl pb-2">
                {data.data.uploadedInvoiceCount}
              </CardTitle>
            </CardContent>

            <CardFooter className="flex justify-center items-center">
              <Button>
                <Link to={`/payments/${id}/invoices`}>View Invoice Pdfs</Link>
              </Button>
            </CardFooter>
          </Card>
          <Card className="w-[200px]  flex flex-col justify-between ">
            <CardHeader>
              <CardDescription className="text-bold text-lg">
                Approved Invoice
              </CardDescription>
            </CardHeader>
            <CardContent className="flex ">
              <CardTitle className="text-4xl pb-2 ">
                {data.data.approvedInvoiceCount}
              </CardTitle>
            </CardContent>
            <CardFooter className="flex  items-end">
              <Button>
                <Link to={`/payments/${id}/invoices`}>View Invoice Pdfs</Link>
              </Button>
            </CardFooter>
          </Card>
          <Card className="w-[200px]  flex flex-col justify-between ">
            <CardHeader>
              <CardDescription className="text-bold text-lg">
                Accounting
              </CardDescription>
            </CardHeader>
            <CardContent className="flex ">
              <CardTitle className="text-4xl pb-2 ">
                {data.data.accountingCount}
              </CardTitle>
            </CardContent>
            <CardFooter className="flex  items-end">
              <Button>
                <Link to={`/payments/${id}/invoices`}>View Invoice Pdfs</Link>
              </Button>
            </CardFooter>
          </Card>
          <Card className="w-[200px]  flex flex-col justify-between ">
            <CardHeader>
              <CardDescription className="text-bold text-lg">
                LUCA
              </CardDescription>
            </CardHeader>
            <CardContent className="flex ">
              <CardTitle className="text-4xl pb-2 ">
                {data.data.lucaCount}
              </CardTitle>
            </CardContent>
            <CardFooter className="flex  items-end">
              <Button>
                <Link to={`/payments/${id}/invoices`}>View Invoice Pdfs</Link>
              </Button>
            </CardFooter>
          </Card>
          <Card className="w-[200px]  flex flex-col justify-between ">
            <CardHeader>
              <CardDescription className="text-bold text-lg">
                Paid
              </CardDescription>
            </CardHeader>
            <CardContent className="flex ">
              <CardTitle className="text-4xl pb-2 ">
                {data.data.paidCount}
              </CardTitle>
            </CardContent>
            <CardFooter className="flex  items-end">
              <Button>
                <Link to={`/payments/${id}/invoices`}>View Invoice Pdfs</Link>
              </Button>
            </CardFooter>
          </Card>
          <Card className="w-[200px]  flex flex-col justify-between bg-red-500 text-white">
            <CardHeader>
              <CardDescription className="text-bold text-lg">
                Error Invoice Count
              </CardDescription>
            </CardHeader>
            <CardContent className="flex ">
              <CardTitle className="text-4xl pb-2 ">
                {data.data.errorInvoiceCount}
              </CardTitle>
            </CardContent>
            <CardFooter className="flex  items-end">
              <Button>
                <Link to={`/payments/${id}/commissions/error-commissions`}>
                  Error Commissions
                </Link>
              </Button>
            </CardFooter>
          </Card>
        </div>
      </div>
    </AppLayout>
  );
}
