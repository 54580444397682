import { useMutation } from "@tanstack/react-query";

import { createAgency } from "../../../data";

import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppLayout } from "../shared/layout";

export function AgencyAddScreen() {
  const pageSettings = {
    title: "Create Agency",
    actions: [
      {
        name: "Go To List",
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          navigate("/agencies");
        },
      },
    ],
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const mutation = useMutation({
    mutationKey: ["createAgency"],
    mutationFn: (data) => {
      return createAgency(data);
    },
    onSuccess: (response) => {
      navigate("/agencies");
    },
  });
  const onSubmit = async (data) => {
    const {
      email,
      name,
      phoneNumber,
      billingName,
      bankName,
      isHaveAgency,
      IBAN,
      SWIFT,
      socialMediaAccounts,
      customData,
    } = data;

    mutation.mutate({
      email,
      name,
      phoneNumber,
      billingName,
      bankName,
      IBAN,
      SWIFT,
      socialMediaAccounts,
      customData,
    });
  };

  return (
    <AppLayout title={pageSettings.title} actions={pageSettings.actions}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-2  ">
          <div className="w-1/2">
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                Name
              </label>

              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                type="text"
                placeholder="name"
                {...register("name", {
                  required: "Name is required",
                })}
              />
              {errors.name && <p>{errors.name.message}</p>}
            </div>

            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>

              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                placeholder="Email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Invalid email format",
                  },
                })}
              />
              {errors.email && <p>{errors.email.message}</p>}
            </div>
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Phone Number
              </label>

              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="phoneNumber"
                type="text"
                placeholder="Phone Number"
                {...register("phoneNumber", {
                  required: "Phone Number is required",
                })}
              />
              {errors.phoneNumber && <p>{errors.phoneNumber.message}</p>}
            </div>

            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="billingName"
              >
                Billing Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="billingName"
                type="text"
                placeholder="Billing Name"
                {...register("billingName", {
                  required: "Billing Name is required",
                })}
              />
              {errors.billingName && <p>{errors.billingName.message}</p>}
            </div>

            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="bankName"
              >
                Bank Name
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="bankName"
                type="text"
                placeholder="Bank Name"
                {...register("bankName", {
                  required: "Bank Name is required",
                })}
              />
              {errors.bankName && <p>{errors.bankName.message}</p>}
            </div>
          </div>
          <div className="w-1/2">
            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="IBAN"
              >
                IBAN
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="IBAN"
                type="text"
                placeholder="IBAN"
                {...register("IBAN", {
                  required: "IBAN is required",
                })}
              />
              {errors.IBAN && <p>{errors.IBAN.message}</p>}
            </div>

            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="SWIFT"
              >
                SWIFT
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="SWIFT"
                type="text"
                placeholder="SWIFT"
                {...register("SWIFT", {
                  required: "SWIFT is required",
                })}
              />
              {errors.SWIFT && <p>{errors.SWIFT.message}</p>}
            </div>

            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="socialMediaAccounts"
              >
                Social Media Accounts
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="socialMediaAccounts"
                type="text"
                placeholder="Social Media Accounts"
                {...register("socialMediaAccounts", {
                  required: "Social Media Accounts is required",
                })}
              />
              {errors.socialMediaAccounts && (
                <p>{errors.socialMediaAccounts.message}</p>
              )}
            </div>

            <div className="mb-6">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="customData"
              >
                Custom Data
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="customData"
                type="text"
                placeholder="Custom Data"
                {...register("customData", {
                  required: "Custom Data is required",
                })}
              />
              {errors.customData && <p>{errors.customData.message}</p>}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Create Agency
          </button>
        </div>
      </form>
    </AppLayout>
  );
}
