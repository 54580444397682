import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AdminLayout } from "../shared/layout";
import { deleteGptPrompt, getGptPrompts, getUsers } from "../../../data";
import { ItemCard, UserCard } from "../../../components";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";
import AuthStore from "@/stores/AuthStore";

export function GptPromptListScreen() {
  const { toast } = useToast();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery({
    queryKey: ["gptPrompts"],
    queryFn: getGptPrompts,
  });

  const pageSettings = {
    title: "GptPrompt List",
    actions: [
      {
        name: "Create New GptPrompt",
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          navigate("/admin/gptPrompts/add");
        },
      },
    ],
  };

  const mutation = useMutation({
    mutationKey: ["deleteGptPrompt"],
    mutationFn: (data) => {
      return deleteGptPrompt(data);
    },
    onSuccess: (response) => {
      toast({
        title: "GptPrompt Deleted",
        message: `GptPrompt ${response.data.name} has been deleted`,
        type: "success",
      });
      queryClient.invalidateQueries(["gptPrompts"]);
    },
  });

  const goToEdit = (id) => () => {
    navigate(`/admin/gptPrompts/${id}`);
  };

  const onDeleteGptPrompt = (id) => {
    mutation.mutate(id);
  };

  if (isLoading) {
    return (
      <AdminLayout>
        <div>Loading...</div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout
      title={pageSettings.title}
      actions={pageSettings.actions}
      loading={isLoading}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4 gap-4 ">
        {data.data.length ? (
          data.data.map((item) => (
            <ItemCard
              title={item.name}
              editClick={goToEdit(item.id)}
              isCanDelete={
                !item.isDefault && AuthStore.isHavePermission("ROLE_DELETE")
              }
              deleteClick={() => onDeleteGptPrompt(item.id)}
            />
          ))
        ) : (
          <div className=" bg-red-300">Not Found GptPrompts</div>
        )}
      </div>
    </AdminLayout>
  );
}
