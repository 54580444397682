import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { getAgency, updateAgency } from "../../../data";

import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppLayout } from "../shared/layout";
import { ClientCard, CustomDataInput, SocialMediaInput } from "@/components";

export function AgencyUpdateScreen() {
  const queryClient = useQueryClient();
  const pageSettings = {
    title: "Agency Detail",
    actions: [
      {
        name: "Go To List",
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          navigate("/agencies");
        },
      },
    ],
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const { data, isLoading, error } = useQuery({
    queryKey: ["agency", id],
    queryFn: async () => await getAgency(id),
  });

  const mutation = useMutation({
    mutationKey: ["updateAgency", id],
    mutationFn: (data) => {
      return updateAgency(data.id, data.data);
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ["client", id] });
    },
  });
  const onSubmit = async (data) => {
    const { email, name, roles } = data;
    console.log("data a", email, name);
    mutation.mutate({ id: id, data: { email, name, roles } });
  };
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return (
      <AppLayout>
        <div>Loading...</div>
      </AppLayout>
    );
  }

  return (
    <AppLayout
      title={pageSettings.title + " - " + id}
      actions={pageSettings.actions}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-2  ">
          <div className="flex gap-2 ">
            <div className="flex w-1/2 ">
              <div className="flex flex-col w-full gap-3 ">
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="name"
                  >
                    Name
                  </label>

                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="name"
                    type="text"
                    placeholder="Name"
                    {...register("name", {
                      required: "Name is required",
                    })}
                    defaultValue={data.data.name}
                  />
                  {errors.name && <p>{errors.name.message}</p>}
                </div>
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Email
                  </label>

                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    type="text"
                    placeholder="Name"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Invalid email format",
                      },
                    })}
                    defaultValue={data.data.email}
                  />
                  {errors.email && <p>{errors.email.message}</p>}
                </div>

                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="phoneNumber"
                  >
                    Phone Number
                  </label>

                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="phoneNumber"
                    type="text"
                    placeholder="Phone Number"
                    {...register("phoneNumber", {
                      required: "Phone Number is required",
                    })}
                    defaultValue={data.data.phoneNumber}
                  />
                  {errors.phoneNumber && <p>{errors.phoneNumber.message}</p>}
                </div>

                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="billingName"
                  >
                    Billing Name
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="billingName"
                    type="text"
                    placeholder="Billing Name"
                    {...register("billingName", {
                      required: "Billing Name is required",
                    })}
                    defaultValue={data.data.billingName}
                  />
                  {errors.billingName && <p>{errors.billingName.message}</p>}
                </div>

                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="bankName"
                  >
                    Bank Name
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="bankName"
                    type="text"
                    placeholder="Bank Name"
                    {...register("bankName", {
                      required: "Bank Name is required",
                    })}
                    defaultValue={data.data.bankName}
                  />
                  {errors.bankName && <p>{errors.bankName.message}</p>}
                </div>
              </div>
            </div>

            <div className="grid w-1/2 ">
              <div className="flex flex-col w-full gap-3">
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="IBAN"
                  >
                    IBAN
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="IBAN"
                    type="text"
                    placeholder="IBAN"
                    {...register("IBAN", {
                      required: "IBAN is required",
                    })}
                    defaultValue={data.data.IBAN}
                  />
                  {errors.IBAN && <p>{errors.IBAN.message}</p>}
                </div>

                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="SWIFT"
                  >
                    SWIFT
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="SWIFT"
                    type="text"
                    placeholder="SWIFT"
                    {...register("SWIFT", {
                      required: "SWIFT is required",
                    })}
                    defaultValue={data.data.SWIFT}
                  />
                  {errors.SWIFT && <p>{errors.SWIFT.message}</p>}
                </div>

                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="roles"
                  >
                    Social Medias
                  </label>
                  {/* <SocialMediaIcons data={data.data.socialMediaAccounts} /> */}
                </div>
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="socialMediaAccounts"
                  >
                    Social Media Accounts
                  </label>
                  <SocialMediaInput
                    value={data.data.socialMediaAccounts}
                    onDataChanged={(data) => {
                      console.log("data", data);

                      setValue("socialMediaAccounts", data);
                    }}
                  />

                  {errors.socialMediaAccounts && (
                    <p>{errors.socialMediaAccounts.message}</p>
                  )}
                </div>

                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="customData"
                  >
                    Custom Data
                  </label>

                  <CustomDataInput
                    value={data.data.customData}
                    onDataChanged={(data) => {
                      setValue("customData", data);
                    }}
                  />

                  {errors.customData && <p>{errors.customData.message}</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Update
          </button>
        </div>

        {data.data.clients && (
          <div className="p-5">
            <h1>Clients</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4 gap-4 ">
              {data.data.clients.map((client) => (
                <ClientCard
                  key={client.id}
                  email={client.email}
                  name={client.name}
                  socialMediaAccounts={client.socialMediaAccounts}
                  editClick={() => {
                    navigate(`/clients/${client.id}`);
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </form>
    </AppLayout>
  );
}
