import { Navigate } from "react-router-dom";
import AuthStore from "../stores/AuthStore";
import { observer } from "mobx-react";

export const ProtectedAdminRoute = observer(({ children, permission }) => {
  const isHavePermission = AuthStore.isHavePermission(permission);

  if (!isHavePermission) {
    return <div>You dont have a permission</div>;
  }

  return children;
});
