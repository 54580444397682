import { makeAutoObservable } from 'mobx';


// MobX store
class ViewStore {
    isTableView = false;
  
    constructor() {
      makeAutoObservable(this);
      this.loadView();
    }
  
    setTableView(value) {
      this.isTableView = value;
      localStorage.setItem('isTableView', JSON.stringify(value));
    }
  
    async loadView() {
      try {
        const value = await localStorage.getItem('isTableView');
        if (value !== null) {
          this.isTableView = JSON.parse(value);
        }
        // Sistem temasını kontrol etmek için ayrı bir fonksiyon kullanacağız
      } catch (error) {
        console.error('Error loading theme:', error);
      }
    }
  
    get getIsTableView() {
      return this.isTableView;
    }
}

export default new ViewStore();