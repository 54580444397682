import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AdminLayout } from "../shared/layout";
import { addRole, getPermissions } from "../../../data";

import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CardBox } from "../shared/CardBox";

export function RoleAddScreen() {
  const queryClient = useQueryClient();
  const pageSettings = {
    title: "Create Role",
    actions: [
      {
        name: "Go To List",
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          navigate("/admin/roles");
        },
      },
    ],
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ["permissions"],
    queryFn: getPermissions,
  });

  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const mutation = useMutation({
    mutationKey: ["createRole"],
    mutationFn: (data) => {
      return addRole(data);
    },
    onSuccess: (response) => {
      navigate("/admin/roles");
    },
  });
  const onSubmit = async (data) => {
    const { name, permissions } = data;

    console.log("permissions", permissions);
    mutation.mutate({ name, permissions });
  };

  return (
    <AdminLayout title={pageSettings.title} actions={pageSettings.actions}>
      <CardBox title="Create Role" description="Create a new role">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Name
            </label>

            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              placeholder="name"
              {...register("name", {
                required: "Name is required",
              })}
            />
            {errors.name && <p>{errors.name.message}</p>}
          </div>
          {/* create multi select for permissions */}

          {!isLoading && (
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="permissions"
              >
                Permissions
              </label>
              <select
                multiple
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="permissions"
                {...register("permissions", {
                  required: "Permissions are required",
                })}
              >
                {data.data.map((permission) => (
                  <option key={permission.id} value={permission.id}>
                    {permission.name}
                  </option>
                ))}
              </select>
              {errors.permissions && <p>{errors.permissions.message}</p>}
            </div>
          )}
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Create Role
            </button>
          </div>
        </form>
      </CardBox>
    </AdminLayout>
  );
}
