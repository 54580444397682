import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { FormInput } from "./form";
import { Button } from "./ui/button";

export function SocialMediaInput({ value, onDataChanged }) {
  const [customData, setCustomData] = useState([
    { platform: "", accountId: "" },
  ]);

  useEffect(() => {
    if (value) {
      console.log(value);
      setCustomData(value);
    }
  }, [value]);

  const handleAddCustomData = () => {
    setCustomData([...customData, { platform: "", accountId: "" }]);
    onDataChanged([...customData, { platform: "", accountId: "" }]);
  };

  const handleRemoveCustomData = (index) => {
    setCustomData(customData.filter((_, idx) => idx !== index));
    onDataChanged(customData.filter((_, idx) => idx !== index));
  };

  const handleChange = (index, field, value) => {
    console.log(index, field, value);
    const newCustomData = customData.map((data, idx) => {
      if (idx === index) {
        return { ...data, [field]: value };
      }
      return data;
    });
    console.log(newCustomData);
    setCustomData(newCustomData);

    onDataChanged(newCustomData);
  };

  return (
    <div className="flex flex-col gap-2">
      {customData.map((data, index) => (
        <div className="flex items-center justify-between gap-2" key={index}>
          <Select
            onValueChange={(value) => handleChange(index, "platform", value)}
            value={data.platform}
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select a Platform" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Platforms</SelectLabel>
                <SelectItem value="Facebook">Facebook</SelectItem>
                <SelectItem value="twitter">Twitter</SelectItem>
                <SelectItem value="instagram">Instagram</SelectItem>
                <SelectItem value="linkedin">LinkedIn</SelectItem>
                <SelectItem value="youtube">YouTube</SelectItem>
                <SelectItem value="pinterest">Pinterest</SelectItem>
                <SelectItem value="snapchat">Snapchat</SelectItem>
                <SelectItem value="tiktok">TikTok</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>

          <FormInput
            type="text"
            value={data.accountId}
            onChange={(e) => handleChange(index, "accountId", e.target.value)}
            placeholder="Hesap Adı"
          />
          <Button
            variant={"destructive"}
            type="button"
            onClick={() => handleRemoveCustomData(index)}
          >
            Remove
          </Button>
        </div>
      ))}
      <Button type="button" onClick={handleAddCustomData}>
        Add Social Media Account
      </Button>
    </div>
  );
}
