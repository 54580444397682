import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { ItemCard, ConfirmationCard } from "../../../components";
import { deleteConfirmation, getConfirmations } from "../../../data";
import { AppLayout } from "../shared/layout";

export function ConfirmationListScreen() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery({
    queryKey: ["confirmations"],
    queryFn: getConfirmations,
  });

  const fileSelect = useRef(null);
  const pageSettings = {
    title: "Confirmation List",
    actions: [
      {
        name: "Create New Confirmation",
        color: "bg-blue-500 hover:bg-blue-700 text-white",
        icon: "add-icon",
        onClick: () => {
          navigate("/confirmations/add");
        },
      },
    ],
  };

  const goToDetail = (id) => () => {
    window.location.href = `/confirmations/${id}`;
  };

  // const mutation = useMutation({
  //   mutationKey: ["deleteConfirmation"],
  //   mutationFn: (data) => {
  //     return deleteConfirmation(data);
  //   },
  //   onSuccess: (response) => {
  //     queryClient.invalidateQueries(["confirmations"]);
  //   },
  // });

  // const onDeleteConfirmation = (id) => {
  //   console.log("delete confirmations", id);
  //   mutation.mutate(id);
  // };

  if (isLoading) {
    return (
      <AppLayout>
        <div>Loading...</div>
      </AppLayout>
    );
  }

  return (
    <AppLayout
      title={pageSettings.title}
      actions={pageSettings.actions}
      loading={isLoading}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-4 gap-4 ">
        {data.data.length ? (
          data.data.map((item) => (
            <ConfirmationCard
              name={item.name}
              monthYear={item.monthYear}
              editClick={goToDetail(item.id)}
            />
          ))
        ) : (
          <div className=" bg-red-300">Not Found Confirmations</div>
        )}
      </div>
    </AppLayout>
  );
}
