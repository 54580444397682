import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ClientCard, SearchView } from "../../../components";
import {
  deleteClient,
  downloadClientsWithNullLucaId,
  getClients,
  uploadClientsLucaInfo,
  uploadClientWithExcel,
} from "../../../data";
import { AppLayout } from "../shared/layout";
import { Button } from "@/components/ui/button";
import ListView from "../../../components/list-view";
import { countryOptions } from "@/utils/country-options";
import { toast } from "@/components/ui/use-toast";

export function ClientListScreen() {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const searchTimeoutRef = useRef(null);
  const [searchParams, setSearchParams] = useState({
    name: "",

    email: "",
    lucaId: "",
    country: "",
  });
  const [queryParams, setQueryParams] = useState({
    name: "",
    lucaId: "",
    email: "",
    country: "",
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isProcess, setIsProcess] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: [
      "clients",
      page,
      pageSize,
      queryParams,
      sortColumn,
      sortDirection,
    ],
    queryFn: () =>
      getClients({
        page,
        pageSize,
        nameFilter: queryParams.name,
        countryFilter: queryParams.country,
        lucaIdFilter: queryParams.lucaId,
        emailFilter: queryParams.email,
        sortColumn,
        sortDirection,
      }),
  });

  const fileSelect = useRef(null);
  const pageSettings = {
    title: "Client List",
    actions: [
      {
        name:
          "Download Null LucaId Clients (Count: " +
          data?.data?.lucaIdNullCount +
          ")",
        color: "bg-blue-500 hover:bg-blue-700 text-white",
        icon: "download-icon",
        onClick: () => {
          downloadClientsWithNullLucaId()
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "clients_null_lucaid.xlsx");
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
            })
            .catch((error) => {
              const errorMessage =
                error.response?.status === 404
                  ? "All clients already have Luca ID"
                  : error.response?.message || "An unexpected error occurred";
              toast({
                title: "Information",
                description: errorMessage,
                variant: "destructive",
              });
              console.log(error);
            });
        },
      },
      {
        name: "Upload Luca Info",
        color: "bg-green-500 hover:bg-green-700 text-white",
        icon: "upload-icon",
        onClick: () => {
          // You'll need to implement a file input and handle the file selection
          const fileInput = document.createElement("input");
          fileInput.type = "file";
          fileInput.accept = ".xlsx,.xls";
          fileInput.onchange = (e) => {
            const file = e.target.files[0];
            if (file) {
              const formData = new FormData();
              formData.append("file", file);
              uploadClientsLucaInfo(formData).then(() => {
                // Refresh the client list
                queryClient.invalidateQueries(["clients"]);
              });
            }
          };
          fileInput.click();
        },
      },
      {
        name: "Excel Upload",
        color: "bg-red-100 hover:bg-red-400 hover:text-black text-red-800",
        icon: "add-icon",
        onClick: () => {
          fileSelect.current.click();
        },
      },
      {
        name: "Create New Client",
        color: "bg-blue-500 hover:bg-blue-700 text-white",
        icon: "add-icon",
        onClick: () => {
          navigate("/clients/add");
        },
      },
    ],
  };

  const goToEdit = (id) => () => {
    window.location.href = `/clients/${id}`;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    setIsProcess(true);

    uploadClientWithExcel(formData).then(() => {
      setIsProcess(false);
      fileSelect.current.value = "";
      queryClient.invalidateQueries("clients");
    });
  };

  const mutation = useMutation({
    mutationKey: ["deleteClient"],
    mutationFn: (data) => {
      return deleteClient(data);
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(["clients"]);
    },
  });

  const onDeleteClient = (id) => {
    console.log("delete client", id);
    mutation.mutate(id);
  };

  const columns = [
    { key: "name", label: "Name" },
    { key: "email", label: "Email" },
    { key: "country", label: "Country" },
    { key: "lucaAccountName", label: "Luca Account Name" },
    { key: "lucaId", label: "Luca ID" },
    {
      key: "actions",
      label: "Actions",

      render: (row) => (
        <div>
          <Button onClick={goToEdit(row.id)}>Edit</Button>
        </div>
      ),
    },
  ];

  const handleSearch = (item) => {
    const newSearchParams = { ...searchParams, [item.key]: item.value };
    setSearchParams(newSearchParams);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      setQueryParams(newSearchParams);
      setPage(1);
    }, 300);
  };

  const handleSort = (column, sortDirection) => {
    setSortColumn(column);
    setSortDirection(sortDirection);
  };

  return (
    <AppLayout title={pageSettings.title} actions={pageSettings.actions}>
      <input
        ref={fileSelect}
        hidden
        type="file"
        onChange={handleFileChange}
        className="mb-4"
      />

      <SearchView
        searchParams={searchParams}
        handleSearch={handleSearch}
        countryOptions={countryOptions}
      />

      <ListView
        isLoading={isLoading}
        handleSort={handleSort}
        data={data?.data?.clients}
        columns={columns}
        totalItems={data?.data?.totalItems}
        enablePagination={true}
        itemsPerPage={10}
        currentPage={page}
        onPageChange={setPage}
        renderGridItem={(item) => (
          <ClientCard
            email={item.email}
            name={item.name}
            socialMediaAccounts={item.socialMediaAccounts}
            editClick={goToEdit(item.id)}
          />
        )}
      />
    </AppLayout>
  );
}
