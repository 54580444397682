import React, { useEffect, useState } from "react";

import { AdminLayout } from "../shared/layout";

import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { CardBox } from "../shared/CardBox";
import {
  getWaitingPageStatus,
  updateWaitingPageStatus,
} from "@/data/requests/setting-requests";

export function SettingScreen() {
  const [status, setStatus] = useState("inactive");

  useEffect(() => {
    const fetchStatus = async () => {
      const response = await getWaitingPageStatus();
      setStatus(response.data);
    };

    fetchStatus();
  }, []);

  const updateStatus = async (newStatus) => {
    await updateWaitingPageStatus({ status: newStatus });
    setStatus(newStatus);
  };
  const pageSettings = {
    title: "General Settings",
    actions: [
      {
        name: "Save",
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          //Save Settings
        },
      },
    ],
  };
  return (
    <div>
      <AdminLayout title={pageSettings.title} actions={pageSettings.actions}>
        <CardBox
          title="General Settings"
          description="Update your site settings"
        >
          <form className="grid gap-6">
            <div className="grid gap-2">
              <Label htmlFor="site-title">Site Title</Label>
              <Input id="site-title" placeholder="Enter site title" />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="site-description">Site Description</Label>
              <Textarea
                id="site-description"
                placeholder="Enter site description"
              />
            </div>
            <div className="grid gap-2">
              <Label htmlFor="site-logo">Site Logo</Label>
              <div className="flex items-center gap-4">
                <img
                  alt="Site Logo"
                  className="rounded-md"
                  height={80}
                  src="/placeholder.svg"
                  style={{
                    aspectRatio: "80/80",
                    objectFit: "cover",
                  }}
                  width={80}
                />
                <Button variant="outline">Upload Logo</Button>
              </div>
            </div>
          </form>
        </CardBox>
        <CardBox
          title="Waiting Page Settings"
          description="If you enable waiting page, users will see this page while the site is under maintenance"
        >
          <form className="grid gap-6">
            <div className="grid gap-2">
              {status === "active" ? (
                <Button
                  variant="destructive"
                  onClick={() => updateStatus("inactive")}
                >
                  Deactivate Waiting Page
                </Button>
              ) : (
                <Button onClick={() => updateStatus("active")}>
                  Enable Waiting Page
                </Button>
              )}
            </div>
          </form>
        </CardBox>
      </AdminLayout>
    </div>
  );
}
