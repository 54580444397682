import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import { useTableView } from "@/hooks/useTableView";
import { Input } from "./ui/input";
import DataTable from "./table";
import { Grid } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  ChevronsLeft,
  ChevronLeft,
  ChevronRight,
  ChevronsRight,
  ArrowUpDown,
} from "lucide-react";

const ListView = observer(
  ({
    data,
    isLoading,
    columns,
    searchParams,
    renderGridItem,
    enablePagination = true,
    itemsPerPage = 10,
    currentPage = 1,
    totalItems = 0,
    handleSearch,
    onPageChange,
    handleSort,
    renderSearchInputs,
  }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const { isTableView } = useTableView();

    const pageCount = Math.ceil(totalItems / itemsPerPage);
    if (isLoading) return <div>Loading...</div>;

    return (
      <div>
        {isTableView ? (
          <DataTable
            data={data}
            columns={columns}
            isLoading={isLoading}
            handleSort={handleSort}
          />
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {data.map((item) => renderGridItem && renderGridItem(item))}
          </div>
        )}
        {enablePagination && (
          <div className="flex  justify-end">
            <div className="flex items-center space-x-2 py-4">
              <Button
                variant="outline"
                size="sm"
                onClick={() => onPageChange(1)}
                disabled={currentPage === 1}
              >
                <ChevronsLeft className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <ChevronLeft className="h-4 w-4" />
              </Button>
              <span>
                {currentPage} / {pageCount}
              </span>
              <Button
                variant="outline"
                size="sm"
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === pageCount}
              >
                <ChevronRight className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => onPageChange(pageCount)}
                disabled={currentPage === pageCount}
              >
                <ChevronsRight className="h-4 w-4" />
              </Button>
            </div>
            <div className="flex items-center  pl-4 space-x-2 py-4">
              Total: {totalItems}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default ListView;
