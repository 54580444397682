import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AdminLayout } from "../shared/layout";
import { addGptPrompt, getPermissions } from "../../../data";

import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CardBox } from "../shared/CardBox";
import { Check } from "lucide-react";
import { Checkbox } from "@/components/ui/checkbox";

export function GptPromptAddScreen() {
  const queryClient = useQueryClient();
  const pageSettings = {
    title: "Create GptPrompt",
    actions: [
      {
        name: "Go To List",
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          navigate("/admin/gptPrompts");
        },
      },
    ],
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const mutation = useMutation({
    mutationKey: ["createGptPrompt"],
    mutationFn: (data) => {
      return addGptPrompt(data);
    },
    onSuccess: (response) => {
      navigate("/admin/gptPrompts");
    },
  });
  const onSubmit = async (data) => {
    const {
      name,
      systemPrompt,
      userPrompt,
      assistantPrompt,
      isForAgencyLocal,
      isDefaultLocal,
    } = data;

    console.log(data);
    var isForAgency = isForAgencyLocal == "on" ? true : false;
    var isDefault = isDefaultLocal == "on" ? true : false;
    mutation.mutate({
      name,
      systemPrompt,
      userPrompt,
      assistantPrompt,
      isForAgency,
      isDefault,
    });
  };

  return (
    <AdminLayout title={pageSettings.title} actions={pageSettings.actions}>
      <CardBox title="Create GptPrompt" description="Create a new gptPrompt">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Name
            </label>

            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              type="text"
              placeholder="name"
              {...register("name", {
                required: "Name is required",
              })}
            />
            {errors.name && <p>{errors.name.message}</p>}
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="systemPrompt"
            >
              System Prompt
            </label>

            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="systemPrompt"
              type="text"
              placeholder="systemPrompt"
              {...register("systemPrompt", {
                required: "systemPrompt is required",
              })}
            />
            {errors.systemPrompt && <p>{errors.systemPrompt.message}</p>}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="userPrompt"
            >
              user Prompt
            </label>

            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="userPrompt"
              type="text"
              placeholder="userPrompt"
              {...register("userPrompt", {
                required: "userPrompt is required",
              })}
            />
            {errors.userPrompt && <p>{errors.userPrompt.message}</p>}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="assistantPrompt"
            >
              assistantPrompt
            </label>

            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="assistantPrompt"
              type="text"
              placeholder="assistantPrompt"
              {...register("assistantPrompt", {
                required: "assistantPrompt is required",
              })}
            />
            {errors.assistantPrompt && <p>{errors.assistantPrompt.message}</p>}
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="isForAgency"
            >
              isForAgency
            </label>

            <Checkbox
              id="isForAgencyLocal"
              name="isForAgencyLocal"
              onCheckedChange={(data) => {
                setValue("isForAgencyLocal", data);
              }}
              {...register("isForAgencyLocal")}
            />
            {errors.isForAgency && <p>{errors.isForAgency.message}</p>}
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="isForAgency"
            >
              isDefault
            </label>

            <Checkbox
              id="isDefault"
              name="isDefaultLocal"
              onCheckedChange={(data) => {
                setValue("isDefaultLocal", data);
              }}
              {...register("isDefaultLocal")}
            />
            {errors.isDefault && <p>{errors.isDefault.message}</p>}
          </div>

          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Create GptPrompt
            </button>
          </div>
        </form>
      </CardBox>
    </AdminLayout>
  );
}
