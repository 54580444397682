import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  render,
  PDFViewer,
} from "@react-pdf/renderer";
import { InvoicePdf } from "./invoice-pdf";

Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontFamily: "Roboto",
    width: "1123px",
    height: "794px",
    fontSize: 12,
  },
  section: {
    margin: 10,
    padding: 10,
  },
});

export const InvoicePdfPreview = () => {
  return (
    <PDFViewer style={{ width: "100%", height: "1000px" }}>
      <InvoicePdf
        data={{
          recipientName: "ad",
          finalAmount: "test",
          bankAccountHolder: "test",
          swift: "test",
          iban: "test",
          invoiceDate: "test",
          phoneNumber: "test",
        }}
      />
    </PDFViewer>
  );
};
